import React from 'react'
import { css } from '@emotion/core'

export default ({ children, theme }) => (
  <h2
    css={theme => css(`
      display: block;
      font-size: 1rem;
      font-weight: normal;
      margin-top: 0;
      margin-bottom: 0;
      text-transform: uppercase;
    `)}
  >
    {children}
  </h2>
)
