import React from 'react'
import { CSSTransition } from 'react-transition-group'

class InfoGroup extends React.Component {
  constructor(props) {
    super(props)
    this.addLinkEventListeners = this.addLinkEventListeners.bind(this)
  }

  addLinkEventListeners() {
    const { onLinkMouseEnter, onLinkMouseLeave } = this.props
    const markdDownLinks = document.querySelectorAll('.project-info a')
    markdDownLinks.forEach(el => {
      el.addEventListener('mouseenter', onLinkMouseEnter)
      el.addEventListener('mouseleave', onLinkMouseLeave)
      el.addEventListener('click', this.handleLinkClick)
    })
  }

  handleLinkClick(e) {
    e.preventDefault()
    e.stopPropagation()
    window.open(e.target.href, e.target.hostname)
  }

  render() {
    const { children, infoOpen } = this.props
    return (
      <CSSTransition
        in={infoOpen}
        timeout={250}
        classNames="info"
        onEnter={() => this.addLinkEventListeners()}
        unmountOnExit
      >
        {children}
      </CSSTransition>
    )
  }
}

export default InfoGroup
