import React from 'react'
import { css } from '@emotion/core'

export default ({ children, theme, icon }) => (
  <h1
    css={theme => css(`
        display: block;
        font-size: ${icon ? 2.4 : 1.25}rem;
        line-height: 1.6rem;
        font-weight: normal;
        text-transform: uppercase;
        margin: 0;
    `)}>
    {children}
  </h1>
)
