import React from 'react'
import { css } from '@emotion/core'

import H1 from '../shared/typography/Heading1'

const NavigationItem = ({ section, active, handleMouseEnter, handleClick }) => (
  <div
    onMouseEnter={handleMouseEnter}
    onTouchEnd={handleMouseEnter}
    onMouseUp={handleClick}
    css={theme => css(`
      transition: opacity 0.1s;
      margin-bottom: 0.6rem;
      line-height: 1.45rem;
      opacity: ${active ? 1 : 0.5};

      @media (hover: hover) {
        &:hover {
          opacity: 1;
        }
      }

      @media (min-width: ${theme.breakpoints.md}) {
        opacity: ${active ? 1 : 0.5};
        margin-bottom: 0.2rem;
      }

    `)}
  >
    <H1>
      {section}
    </H1>
  </div>
)

export default NavigationItem
