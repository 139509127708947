import React from 'react'
import { observer, inject } from 'mobx-react'
import { CSSTransition } from 'react-transition-group'
import { css } from '@emotion/core'

import Section from '../shared/section'
import ProjectVideo from './video'
import ProjectInfo from './info'
import ProjectVideoButton from './video-button'
import ProjectPeekTitle from './peek-title'
import Popup from './popup'

const ProjectContainer = inject(`projectStore`)(
  observer(
    class Container extends React.Component {
      constructor(props) {
        super(props)

        this.state = {
          active: false,
        }

        this.blurrableEl = React.createRef()
        this.goToNextVideo = this.goToNextVideo.bind(this)
        this.handleMouseClick = this.handleMouseClick.bind(this)
      }

      goToNextVideo() {
        const { projectStore, project } = this.props
        const { videoIndex } = projectStore
        const videosLength = project.videos.length
        const newIndex = videoIndex === (videosLength - 1) ? 0 : videoIndex + 1
        projectStore.updateActiveVideo(newIndex)
      }

      handleMouseClick(e) {
        e.preventDefault()
        const { projectStore, isTouch } = this.props
        if (!isTouch) {
          projectStore.toggleAudio()
        }
      }

      render() {
        const {
          projectStore,
          project,
          splashOpen,
          navOpen,
          isTouch,
          onLinkMouseEnter,
          onLinkMouseLeave
        } = this.props

        const {
          project: activeProject,
          videoIndex,
          videoMode,
          audible,
          infoOpen,
          infoButtonHover,
          videoButtonHover
        } = projectStore

        const { client, videos } = project
        const active = project.id === activeProject.id
        const activeVideo = videos[videoIndex] || videos[0]
        const { poster } = activeVideo || false

        const showInfo = videoMode || !active || splashOpen || navOpen
        const isFF = process.browser ? !!window.sidebar : false

        return (
          <Section>
            <div
              onTouchStart={this.handleTouchStart}
              onClick={this.handleMouseClick}
            >
              {poster &&
                <div
                  ref={this.blurrableEl}
                  css={css(`
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 100%;
                    height: 100%;
                    filter: blur(${!isFF || splashOpen ? 70 : 0}px);
                    will-change: filter;
                    opacity: ${isFF ? 0.2 : videoMode ? 1 : 0.9};
                    background-image: url(${poster.fluid.src});
                    background-size: cover;
                    background-position: center;
                    transform: translate(-50%, -50%) scale(1.1);
                  `)}
                >
                  {active &&
                    <ProjectVideo
                      key={project.id}
                      videoCount={project.videos.length}
                      onVideoEnd={this.goToNextVideo}
                      projectStore={projectStore}
                      audible={audible}
                      videoMode={videoMode}
                      isTouch={isTouch}
                      activeVideo={activeVideo}
                    />
                  }
                </div>
              }

              {active && !navOpen &&
                <CSSTransition
                  in={!splashOpen}
                  timeout={300}
                  classNames="project-info"
                  unmountOnExit
                >
                  <div css={css(`
                      opacity: 1;
                      transition: opacity 0.3s;
                      transition-delay: 0.5s;

                      &.project-info-enter {
                        opacity: 0;
                      }
                      &.project-info-enter-active {
                        opacity: 1;
                      }
                      &.project-info-exit {
                        opacity: 1;
                      }
                      &.project-info-exit-active {
                        opacity: 0;
                      }
                  `)}>
                    <div css={css(`
                        opacity: ${showInfo ? 0 : 1};
                        transition: opacity 0.3s;
                    `)}>
                      {!project.info || (
                        <ProjectInfo
                          project={project}
                          projectStore={projectStore}
                          videoMode={videoMode}
                          audible={audible}
                          videoIndex={videoIndex}
                          infoOpen={infoOpen}
                          infoButtonHover={infoButtonHover}
                          onLinkMouseEnter={onLinkMouseEnter}
                          onLinkMouseLeave={onLinkMouseLeave}
                        />
                      )}
                    </div>
                    <ProjectVideoButton
                      blurrableEl={this.blurrableEl}
                      videoButtonHover={videoButtonHover}
                      {...this.props}
                    />
                  </div>
                </CSSTransition>
              }

              <ProjectPeekTitle
                title={activeVideo.title}
                client={client}
                active={active}
              />

              {isFF && !splashOpen && (
                <Popup>
                  This site has been optimised for Chrome and Safari
                </Popup>
              )}
            </div>

          </Section>
        )
      }
    }
  )
)

export default ProjectContainer
