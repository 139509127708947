import React from 'react'
import { css } from '@emotion/core'
import { CSSTransition } from 'react-transition-group'

import H2 from '../shared/typography/Heading2'

class Popup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: true
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ open: false })
    }, 12000)
  }

  render() {
    const { open } = this.state
    const { children } = this.props
    return (
      <CSSTransition
        in={open}
        timeout={500}
        classNames="notification"
        unmountOnExit
      >
        <div
          css={theme => css(`
            position: absolute;
            bottom: 3rem;
            left: 8.6%;
            color: ${theme.colours.white};
            transition: opacity 0.5s;

            &.notification-enter {
              opacity: 0;
            }
            &.notification-enter-active {
              opacity: 1;
            }
            &.notification-exit {
              opacity: 1;
            }
            &.notification-exit-active {
              opacity: 0;
            }
        `)}>
          <H2>{children}</H2>
        </div>
      </CSSTransition>
    )
  }
}

export default Popup
