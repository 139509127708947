import React from 'react'
import { css } from '@emotion/core'
import { observer, inject } from 'mobx-react'

import H1 from '../shared/typography/Heading1'

const Cursor = inject(`navStore`)(
  observer(
    class VideoCursor extends React.Component {
      constructor(props) {
        super(props)
        this.state = {
          visible: false
        }
        this.cursorEl = React.createRef()
        this.handleMouseMove = this.handleMouseMove.bind(this)
      }

      componentDidMount() {
        window.addEventListener('mousemove', this.handleMouseMove)
      }

      componentWillUnmount() {
        window.removeEventListener('mousemove', this.handleMouseMove)
      }

      handleMouseMove(e) {
        const el = this.cursorEl.current
        const { visible } = this.state
        el.style.transform = `translate3d(calc(-50% + ${e.clientX}px), calc(-50% + ${e.clientY}px), 0)`
        if (!visible) {
          this.setState({ visible: true })
        }
      }

      render() {
        const {
          navStore,
          audible,
          videoMode,
          videoButtonHover,
          infoButtonHover,
          infoOpen,
          loading,
          projectInfoLinkHover
        } = this.props

        const {
          open: navOpen,
          navHover
        } = navStore

        const { visible } = this.state

        let text = audible ? 'PAUSE' : 'LISTEN'

        if (videoButtonHover) {
          text = videoMode ? 'BACK' : 'WATCH'
        }

        if (infoButtonHover) {
          text = infoOpen ? 'CLOSE' : 'CREDITS'
        }

        if (loading) {
          text = 'LOADING'
        }

        if (navOpen || navHover || projectInfoLinkHover) {
          text = '●'
        }

        return (
          <span
            data={{ navOpen: navOpen }}
            ref={this.cursorEl}
            css={theme => css(`
              position: fixed;
              top: 0;
              left: 0;
              pointer-events: none;
              color: ${theme.colours.white};
              opacity: ${visible ? 1 : 0};
              transition: opacity 0.2s;
              z-index: 3;
            `)}
          >
            <H1 icon={navOpen || navHover || projectInfoLinkHover}>{text}</H1>
          </span>
        )
      }
    }
  )
)

export default Cursor
