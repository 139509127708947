import React from 'react'
import { css } from '@emotion/core'

import ProjectNavigation from './project-navigation'
import InformationContainer from '../information/container'

class SubNavigation extends React.Component {
  render() {
    const {
      navStore,
      projectStore,
      selectedProjects,
      showreels,
      archivedProjects,
    } = this.props

    return (
      <div
        className="sub-nav"
        css={theme => css(`
          @media (min-width: ${theme.breakpoints.md}) {
            grid-area: project;
            position: relative;
            grid-row: 1 / span 2;
            padding-top: 50vh;
            padding-bottom: 3rem;
            height: 100%;
            z-index: 1;
            overflow: scroll;
            scroll-behavior: smooth;
            -ms-overflow-style: none;
            overflow: -moz-scrollbars-none;

            &::-webkit-scrollbar {
                display: none;
            }
          }
      `)}>
        {navStore.activeSection === 'projects' &&
          <ProjectNavigation
            navStore={navStore}
            projectStore={projectStore}
            clients={selectedProjects}
          />
        }
        {navStore.activeSection === 'showreels' &&
          <ProjectNavigation
            navStore={navStore}
            projectStore={projectStore}
            clients={showreels}
          />
        }
        {navStore.activeSection === 'archive' &&
          <ProjectNavigation
            navStore={navStore}
            projectStore={projectStore}
            clients={archivedProjects}
          />
        }
        {navStore.activeSection === 'information' &&
          <InformationContainer navStore={navStore} />
        }
      </div>
    )
  }
}

export default SubNavigation
