import React from 'react'
import moment from 'moment'
import { StaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'
import { CSSTransition } from 'react-transition-group'

import InfoBlock from './block'
import H2 from '../shared/typography/Heading2'

class InfoContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
    this.infoEl = React.createRef()
    this.handleCloseClick = this.handleCloseClick.bind(this)
  }
  componentDidMount() {
    this.setState({ open: true })
  }

  handleCloseClick(e) {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ open: false })
  }

  render() {
    const { navStore } = this.props
    const { open } = this.state
    const {
      address,
      contact,
      profile,
      clients
    } = this.props.data.allContentfulSettings.edges[0].node

    const year = moment().format('YYYY');

    return (
      <CSSTransition
        in={open}
        timeout={250}
        classNames="info"
        onExited={() => navStore.closeNav()}
        unmountOnExit
      >
        <div
          ref={this.infoEl}
          className="information"
          css={theme => css(`
            padding-top: 5rem;
            transition: opacity 0.25s;

            &.info-enter {
              opacity: 0;
            }
            &.info-enter-active {
              opacity: 1;
            }
            &.info-exit {
              opacity: 1;
            }
            &.info-exit-active {
              opacity: 0;
            }

          @media (min-width: ${theme.breakpoints.md}) {
            padding-top: 0;
            background: none;
            display: grid;
            grid-template-columns: repeat(3, 33.3333fr);
            grid-column-gap: 2.27em;
          }
        `)}>
          <div
            css={theme => css(`
              color: ${theme.colours.white};
              grid-column: 2 / span 2;
              position: relative;

              @media (min-width: ${theme.breakpoints.md}) {
                grid-column: 1 / span 3;
              }

              @media (min-width: ${theme.breakpoints.xl}) {
                grid-column: 1 / span 2;
              }
            `)}
          >
            <span
              onClick={this.handleCloseClick}
              onTouchEnd={this.handleCloseClick}
              css={theme => css(`
                position: fixed;
                top: 3rem;
                left: auto;
                right: 0rem;
                font-size: 2em;
                padding: 0 8% 0 0;
                line-height: 1rem;

                @media (min-width: ${theme.breakpoints.md}) {
                  padding: 0 0 0 8%;
                  top: 2.7rem;
                  left: 0.5rem;
                  right: auto;
                }
              `)}
            >
              ×
            </span>
            <div css={theme => css(`
              @media (min-width: ${theme.breakpoints.md}) {
                position: absolute;
                top: calc(-50vh + 2.75rem);
                left: 0;
                width: 100%;
              }
            `)}>
              <div css={css(`
                grid-column: 2 / span 2;

                a {
                  display: block;
                }
              `)}>
                <InfoBlock columns={2} columnsMobile={1}>
                  {address.childMarkdownRemark && (
                    <div dangerouslySetInnerHTML={{
                      __html: address.childMarkdownRemark.html
                    }} />
                  )}
                  {contact.childMarkdownRemark && (
                    <div dangerouslySetInnerHTML={{
                      __html: contact.childMarkdownRemark.html
                    }} />
                  )}
                </InfoBlock>
              </div>
            </div>

            {profile.childMarkdownRemark && (
              <InfoBlock>
                <div dangerouslySetInnerHTML={{
                  __html: profile.childMarkdownRemark.html
                }} />
              </InfoBlock>
            )}
            {clients.childMarkdownRemark && (
              <InfoBlock columns={2}>
                <div>Selected Clients</div>
                <br />
                <div dangerouslySetInnerHTML={{
                  __html: clients.childMarkdownRemark.html
                }} />
              </InfoBlock>
            )}
            <InfoBlock>
              <div css={css(`
                display: grid;
                grid-template-columns: repeat(2, 50fr);
                grid-column-gap: 2.27rem;

                a {
                  display: block;
                }
              `)}>
                <div>Colophon</div>
                <div>
                  <div>Design</div>
                  <a
                    href="http://anyother.name"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Any Other Name
                  </a>
                  <br />
                  <div>Development</div>
                  <a
                    href="http://official.business"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Official Business
                  </a>
                </div>
              </div>
            </InfoBlock>
            <div css={theme => css(`
              column-count: ${2};
              column-gap: 2.27rem;
            `)}>
              <H2>
                {`© ${year} Golden Hum`}
              </H2>
              <H2>
                All rights reserved
              </H2>
            </div>
          </div>
        </div>
      </CSSTransition>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulSettings {
         edges {
           node {
             id
             address {
               childMarkdownRemark {
                 html
               }
             }
             contact {
               childMarkdownRemark {
                 html
               }
             }
             profile {
               childMarkdownRemark {
                 html
               }
             }
             clients {
               childMarkdownRemark {
                 html
               }
             }
           }
         }
       }
      }
    `}
    render={data => <InfoContainer data={data} {...props} />}
  />
)
