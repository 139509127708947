import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { Global, css } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'

import '../css/app.scss'
import Navigation from './navigation/container'
import Splash from './splash'

const theme = {
  colours: {
    white: 'white',
    black: 'black',
  },
  breakpoints: {
    xs: '380px',
    sm: '480px',
    md: '640px',
    lg: '768px',
    xl: '900px',
    xxl: '1200px',
  }
}

const Layout = ({ children, splashOpen }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <ThemeProvider theme={theme}>
          <Global
            styles={css`
              html {
                font-size: 12px;
                line-height: 1.5;
                scroll-behavior: smooth;
              }
              body {
                margin: 0;
                font-family: 'FavoritStd';
                letter-spacing: 0.04em;
                background-color: ${theme.colours.black};
                color: ${theme.colours.white};
                min-height: 100%;
                background: black;
              }
              *::selection {
                background: ${theme.colours.white};
                color: ${theme.colours.black};
                text-shadow: none;
              }

              .fp-viewing-1 {
                overflow: scroll;
              }

              a {
                text-decoration: none;
                color: ${theme.colours.white};
                transition: opacity 0.1s;

                &:hover {
                  opacity: 0.8;
                }

                .markdown & {
                  display: inline-block;
                  line-height: 1.1;
                  text-decoration: none;
                  cursor: none;
                  border-bottom: 1px solid rgba(255, 255, 255, 0.4);

                  &:hover {
                    border-bottom-color: transparent;
                  }
                }
              }
            `}
          />
          <Splash open={splashOpen} />
          <Navigation
            siteTitle={data.site.siteMetadata.title}
          />
          <Helmet meta={[
            {
              name: 'google-site-verification',
              content: 'WjHY0TpmUzEHwOadhW9aY4-xndKcY6kB_vezrQpCeQ4'
            }
          ]} />
          <main>{children}</main>
        </ThemeProvider>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
