import React from 'react'
import { inject } from 'mobx-react'
import { css } from '@emotion/core'

const ProjectVideoButton = inject(`projectStore`)(
  class projectVideoButton extends React.Component {
    constructor(props) {
      super(props)
      this.isFF = false
      this.buttonEl = React.createRef()
      this.measure = this.measure.bind(this)
      this.handleClick = this.handleClick.bind(this)
      this.handleMouseEnter = this.handleMouseEnter.bind(this)
      this.handleMouseLeave = this.handleMouseLeave.bind(this)
    }

    componentDidMount() {
      this.isFF = !!window.sidebar
      document.addEventListener('mousemove', this.measure)
    }

    componentWillUnmount() {
      document.removeEventListener('mousemove', this.measure)
      if (this.props.blurrableEl.current) {
        this.props.blurrableEl.current.style.filter = ``
      }
    }

    measure(e) {
      const { projectStore } = this.props
      const { blurrableEl } = this.props
      const buttonEl = this.buttonEl.current

      if (projectStore.videoMode) {
        blurrableEl.current.style.filter = `blur(0px)`
        return false
      }

      // Get mouse position
      const mouseX = e.clientX
      const mouseY = e.clientY

      const boundsTop = buttonEl.getBoundingClientRect().top
      const boundsLeft = buttonEl.getBoundingClientRect().left

      // Determine hypotenuse
      const x = Math.abs(boundsLeft - mouseX)
      const y = Math.abs(boundsTop - mouseY)
      const distanceFromPoint = Math.hypot(x, y)

      // Get distance (as percentage) up to a quarter of the screen away
      const distanceAsPercentage = (distanceFromPoint / (window.innerWidth / 4)) * 100

      if (100 - distanceAsPercentage > 0) {
        // Blur up to 20px
        const blur = distanceAsPercentage * 0.7
        const opacity = 1 - distanceAsPercentage * 0.008

        // If firefox, change opacity
        if (this.isFF) {
          blurrableEl.current.style.opacity = opacity
        } else {
          blurrableEl.current.style.filter = `blur(${blur}px)`
        }
      }
    }

    handleMouseEnter() {
      const { projectStore } = this.props
      projectStore.showVideoButtonHover()
    }

    handleMouseLeave() {
      const { projectStore } = this.props
      projectStore.hideVideoButtonHover()
    }

    handleClick(e) {
      e.preventDefault()
      e.stopPropagation()
      const { projectStore, blurrableEl } = this.props

      if (!this.isFF) {
        blurrableEl.current.style.filter = `blur(${projectStore.videoMode ? 70 : 0}px)`
      }
      projectStore.toggleVideoMode()
    }

    render() {
      const { projectStore, videoButtonHover, navOpen } = this.props

      return (
        <div
          ref={this.buttonEl}
          onClick={this.handleClick}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          onTouchEnd={this.handleClick}
          css={theme => css(`
            position: absolute;
            right: 1.88rem;
            top: calc(50% - 0.5em);
            padding: 1rem;
            z-index: 1;
            user-select: none;
            opacity: ${videoButtonHover || navOpen ? 0 : 1};
            transition: opacity 0.2s;
            transform-origin: center;
            animation: pulse 2.2s cubic-bezier(0.22, 0.61, 0.36, 1) infinite;

            @media (min-width: ${theme.breakpoints.md}) {
              right: 7.5vw;
              top: calc(50% - 1rem);
            }

            @keyframes pulse {
              0% { transform: scale(1); }
              15% { transform: scale(0.6); }
              40% { transform: scale(1); }
              100% { transform: scale(1); }
            }
          `)}>
          <div
            css={theme => css(`
              color: ${theme.colours.white};

              font-size: 2.4rem;
              font-size: 1.25rem;
              line-height: 1.6rem;

              @media (min-width: ${theme.breakpoints.md}) {
                font-size: 2.4rem;
              }

            `)}
          >
            {projectStore.videoMode ? `○` : `●`}
          </div>
        </div>
      )
    }
  }
)

export default ProjectVideoButton
