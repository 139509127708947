import React from 'react'
import { css } from '@emotion/core'
import { Link, navigate } from 'gatsby'

import H1 from '../shared/typography/Heading1'
import ProjectItem from './project-item'

class ClientItem extends React.Component {
  constructor(props) {
    super(props)
    this.handleLinkClick = this.handleLinkClick.bind(this)
  }

  handleLinkClick(e, { id, slug }) {
    const { navStore, projectStore } = this.props
    const { activeSection: navSection } = navStore

    if (navSection === 'projects') {
      e.preventDefault()
      e.stopPropagation()
      const onProjectsPage = projectStore.path === 'projects'
      if (onProjectsPage) {
        projectStore.updateActiveProject(id)
      } else {
        navigate('/', {
          state: { id }
        })
      }
    }

    navStore.closeNav()
  }

  render() {
    const { projectStore, navStore, client } = this.props
    const { project } = projectStore
    const active = project ? project.client.name === client.name : false

    let { activeSection: navSection } = navStore
    if (navSection === 'archive') {
      navSection = 'projects'
    }

    return (
      <div
        key={`nav-client-${client.id}`}
        css={theme => css(`
          display: grid;
          grid-template-columns: repeat(3, 33.3333fr);
          grid-column-gap: 2.27em;

          @media (hover: hover) {
            &:hover {
              > div:first-of-type {
                opacity: 1;
              }
            }
          }
        `)}
      >
        <div css={theme => css(`
          margin-bottom: 0.6rem;
          line-height: 1.45rem;
          opacity: ${active ? 1 : 0.5};
          transition: opacity 0.1s;

          @media (min-width: ${theme.breakpoints.md}) {
            margin-bottom: 0.2rem;
          }
        `)}>
          {client.projects.length > 1 ? (
            <H1>{client.name}</H1>
          ) : (
            <Link
              to={`/${navSection}/${client.projects[0].slug}`}
              css={css(`
                text-decoration: none;
              `)}
              onClick={e => this.handleLinkClick(e, client.projects[0])}
            >
              <H1>{client.name}</H1>
            </Link>
          )}

        </div>
        <div css={theme => css(`
          grid-column: 2 / span 2;
        `)}>
          {client.projects.map(p => {
            const activeProject = project.title === p.title
            return (
              <ProjectItem
                key={`nav-project-${p.id}`}
                navStore={navStore}
                projectStore={projectStore}
                activeProject={activeProject}
                project={p}
              />
            )
          })}
        </div>
      </div>
    )
  }
}

export default ClientItem
