import React from 'react'
import { css } from '@emotion/core'

import H1 from '../shared/typography/Heading1'

const ClientTitle = ({ navOpen, navStore, name }) => (
  <H1>
    <span
      onMouseUp={() => navStore.openProjectIndex()}
      onTouchEnd={() => navStore.openProjectIndex()}
      onMouseEnter={() => navStore.showNavHover()}
      onMouseLeave={() => navStore.hideNavHover()}
      css={css(`
        pointer-events: ${navOpen ? 'none' : 'auto'};
      `)}
    >
      {name}
    </span>
  </H1>
)

export default ClientTitle
