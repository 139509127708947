import React from 'react'
import { css } from '@emotion/core'
import H1 from '../shared/typography/Heading1'

const AudioToggle = ({
  projectStore,
  loading,
  audible,
  navOpen,
  videoMode,
  playBlocked
}) => {
  let text = audible ? 'PAUSE' : 'LISTEN'
  let animation = 'none'

  if (playBlocked) {
    text = 'PLAY'
  }

  if (loading) {
    text = 'LOADING'
    animation = 'strobe 0.2s infinite alternate'
  }

  return (
    <div
      css={theme => css(`
        pointer-events: auto;
        opacity: ${navOpen || videoMode ? 0 : 1};
        animation: ${animation};

        @keyframes strobe {
          from { opacity: 1; }
          to { opacity: 0.4; }
        }

        @media (min-width: ${theme.breakpoints.md}) {
          display: none;
        }
    `)}>
      <H1>
        <span onTouchEnd={() => projectStore.toggleAudio()}>
          {text}
        </span>
      </H1>
    </div>
  )
}

export default AudioToggle
