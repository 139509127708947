import React from 'react'
import { Link, navigate } from 'gatsby'
import { css } from '@emotion/core'

import H1 from '../shared/typography/Heading1'

class ProjectItem extends React.Component {
  constructor(props) {
    super(props)
    this.handleLinkClick = this.handleLinkClick.bind(this)
  }
  handleLinkClick(e, { id, slug }) {
    const { navStore, projectStore } = this.props
    const { activeSection: navSection } = navStore

    if (navSection === 'projects') {
      e.preventDefault()
      e.stopPropagation()
      const onProjectsPage = projectStore.path === 'projects'
      if (onProjectsPage) {
        projectStore.updateActiveProject(id)
      } else {
        navigate('/', {
          state: { id }
        })
      }
    }

    navStore.closeNav()
  }

  render() {
    const { activeProject, project, navStore } = this.props
    let { activeSection: navSection } = navStore
    const isArchive = navSection === 'archive'
    if (isArchive) {
      navSection = 'projects'
    }

    return (
      <Link
        to={`/${navSection}/${project.slug}`}
        css={css(`
          text-decoration: none;
        `)}
        onClick={e => this.handleLinkClick(e, project)}
      >
        <div
          css={theme => css(`
            margin-bottom: 0.6rem;
            line-height: 1.45rem;
            color: ${theme.colours.white};
            opacity: ${activeProject ? 1 : 0.5};
            transition: opacity 0.1s;
            cursor: none;
            background: transparent;

            @media (hover: hover) {
              &:hover {
                opacity: 1;

                .preview {
                  @media (min-width: ${theme.breakpoints.md}) {
                    display: block;
                  }
                }
              }
            }


            @media (min-width: ${theme.breakpoints.md}) {
              display: grid;
              grid-template-columns: repeat(2, 50fr);
              grid-column-gap: 2.27em;
              margin-bottom: 0.2rem;
            }
          `)}
        >
          <H1>
            {project.title}
          </H1>
          <div
            className='preview'
            css={theme => css(`
              display: none;
              grid-column: 2 / span 1;
              position: fixed;
              top: 50vh;
              right: 8.6%;
              width: calc((100% - (8.6% * 2) - (2.27rem * 3)) * 0.25);
            `)}
          >
            {project.thumbnail && isArchive ? (
              <div css={css(`
                display: block;
                width: 100%;
              `)}>
                <img srcSet={project.thumbnail.poster.fluid.srcSet} alt={project.title} />
              </div>
            ) : project.thumbnail && project.thumbnail.poster && (
              <video
                src={project.thumbnail.video.file.url}
                poster={project.thumbnail.poster.fluid.src}
                css={css(`
                  display: block;
                  width: 100%;
                `)}
                muted
                autoPlay
                playsInline
                loop
              />
            )}
          </div>
        </div>
      </Link>
    )
  }
}

export default ProjectItem
