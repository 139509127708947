import React from 'react'
import { css } from '@emotion/core'

const VideoCount = ({ videos, videoIndex, projectStore, navStore }) => {
  const numbers = videos.map((v, i) => {
    const active = i === videoIndex
    const char = active ? `&#1010${i + 2};` : `&#931${i + 2};`
    return (
      <span
        key={`video-count-${i}`}
        onMouseUp={() => projectStore.updateActiveVideo(i)}
        onTouchEnd={() => projectStore.updateActiveVideo(i)}
        onMouseEnter={() => navStore.showNavHover()}
        onMouseLeave={() => navStore.hideNavHover()}
        dangerouslySetInnerHTML={{ __html: char }}
      />
    )
  })
  return (
    <div css={css(`
      display: inline-block;
      margin-left: 1rem;
      pointer-events: auto;
    `)}>
      {numbers}
    </div>
  )
}

export default VideoCount
