import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/core'

import H1 from '../shared/typography/Heading1'

class Brand extends React.Component {
  constructor(props) {
    super(props)
    this.brandEl = React.createRef()
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(e) {
    e.preventDefault()
    e.stopPropagation()
    const { store } = this.props

    if (store.open) {
      store.closeNav()
    } else {
      store.openNav()
    }
  }

  render() {
    const { siteTitle } = this.props
    return (
      <div
        ref={this.brandEl}
        css={theme => css(`
          margin-bottom: 0.6rem;
          line-height: 1.45rem;

          @media (min-width: ${theme.breakpoints.md}) {
            margin-bottom: 0.2rem;
          }
        `)}
      >
        <H1>
          <Link
            to="/"
            onClick={this.handleClick}
            onTouchEnd={this.handleClick}
            css={theme => css(`
              color: ${theme.colours.white};
              text-decoration: none;
              pointer-events: auto;
              cursor: none;
            `)}
          >
            {siteTitle}
          </Link>
        </H1>
      </div>
    )
  }
}

export default Brand
