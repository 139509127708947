import React from 'react'
import { css } from '@emotion/core'
import { observer, inject } from 'mobx-react'

import H2 from '../shared/typography/Heading2'

const ProjectPeekTitle = inject(`navStore`)(
  observer(({ title, client, active, navStore }) => (
    <div
      data={{ navOpen: navStore.open }}
      css={theme => css(`
        display: none;
        width: 100%;
        height: 4.545em;
        position: relative;
        padding: 0 8.6%;
        grid-template-columns: repeat(8, 12.8fr);
        grid-column-gap: 2.27em;
        grid-template-areas:". title . . . . . .";
        align-items: center;
        color: ${theme.colours.white};
        z-index: 1;
        opacity: ${active || navStore.open ? 0 : 1};
        transition: opacity 0.2s;
        pointer-events: none;

        @media (min-width: ${theme.breakpoints.md}) {
          display: grid;
        }
      `)}
    >
      <div css={css(`
        grid-area: title;
        grid-column: 2 / span 6;
      `)}>
        <H2>{client.name} — {title}</H2>
      </div>
    </div>
  ))
)

export default ProjectPeekTitle
