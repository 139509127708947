import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'

const Section = ({ children }) => (
  <section
    className="project section"
    css={theme => css(`
      position: relative;
      width: 100vw;
      height: 100%;
      overflow: hidden;
      cursor: none;
    `)}
  >
    {children}
  </section>
)

Section.propTypes = {
  children: PropTypes.node.isRequired
}

export default Section
