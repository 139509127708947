import React from 'react'
import { observer, inject } from 'mobx-react'
import { CSSTransition } from 'react-transition-group'
import { css } from '@emotion/core'

const Splash = inject(`navStore`)(
  observer(
    class Splash extends React.Component {
      constructor(props) {
        super(props)
        this.splashEl = React.createRef()
      }
      componentDidMount() {
        const { navStore } = this.props
        setTimeout(() => navStore.hideSplash(), 3000)
      }

      render() {
        const { open } = this.props
        return (
          <CSSTransition
            in={open}
            timeout={500}
            classNames="splash"
            unmountOnExit
          >
            <div
              ref={this.splashEl}
              css={css`
                position: fixed;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 5;
                color: white;
                cursor: none;
                font-size: 2.5rem;
                line-height: 1;
                letter-spacing: 1rem;
                padding-left: 0.5rem;
                transition: opacity 0.5s;

                &:before {
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0;
                  display: block;
                  width: 100%;
                  height: 100%;
                  background-color: black;
                  animation: fade-out 1s 1;
                  animation-delay: 0.5s;
                  animation-fill-mode: forwards;
                }

                &.splash-enter {
                  opacity: 0;
                }
                &.splash-enter-active {
                  opacity: 1;
                }
                &.splash-exit {
                  opacity: 1;
                }
                &.splash-exit-active {
                  opacity: 0;
                }

                @keyframes fade-out {
                  0% {
                    opacity: 1;
                  }
                  100% {
                    opacity: 0;
                  }
                }
              `}
            >
              {/* <MonogramSVG /> */}
            </div>
          </CSSTransition>
        )
      }
    }
  )
)

const MonogramCSS = () => (
  <div>
    <div>●●●●●</div>
    <div>●○○○○</div>
    <div>●○●●●</div>
    <div>●○○○●</div>
    <div>●●●●●</div>
  </div>
)

const MonogramSVG = () => (
  <svg width="241px" height="104px" viewBox="0 0 241 104" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g fill="#FFFFFF" fillRule="nonzero" stroke="none">
      <path d="M6.29621622,12.1708108 C9.54842706,12.1708108 12.1848649,9.53437301 12.1848649,6.28216216 C12.1848649,3.02995132 9.54842706,0.393513514 6.29621622,0.393513514 C3.04400537,0.393513514 0.407567568,3.02995132 0.407567568,6.28216216 C0.415294128,9.53116718 3.0472112,12.1630843 6.29621622,12.1708108" id="Path"></path>
      <path d="M6.28216216,12.5643243 C9.75170452,12.5643243 12.5643243,9.75170452 12.5643243,6.28216216 C12.5643243,2.8126198 9.75170452,0 6.28216216,0 C2.8126198,0 0,2.8126198 0,6.28216216 C0,9.75170452 2.8126198,12.5643243 6.28216216,12.5643243 Z M6.28216216,11.7772973 C3.24728283,11.7772973 0.787027027,9.3170415 0.787027027,6.28216216 C0.787027027,3.24728283 3.24728283,0.787027027 6.28216216,0.787027027 C9.3170415,0.787027027 11.7772973,3.24728283 11.7772973,6.28216216 C11.7772973,9.3170415 9.3170415,11.7772973 6.28216216,11.7772973 Z" id="Oval"></path>
      <path d="M125.755599,6.25226056 C125.739026,3.00599456 123.09838,0.38545528 119.852082,0.393694228 C116.605784,0.401933177 113.978474,3.03584227 113.978378,6.28215058 C113.98236,7.85127569 114.610146,9.35335885 115.723204,10.4579414 C116.836261,11.562524 118.343099,12.1788113 119.911198,12.1708057 C123.158421,12.1458915 125.772172,9.49852656 125.755599,6.25226056 Z M116.277585,9.89930653 C115.312935,8.94200165 114.768853,7.64019626 114.765404,6.28116343 C114.765488,3.46973958 117.041654,1.18785652 119.854079,1.18071872 C122.666505,1.17358092 124.954224,3.44388106 124.968582,6.25627857 C124.98294,9.0685077 122.718792,11.3619419 119.906675,11.3837915 C118.547837,11.3905948 117.242115,10.8564928 116.277585,9.89930653 Z" id="Path"></path>
      <path d="M233.536216,12.1708108 C236.788427,12.1708108 239.424865,9.53437301 239.424865,6.28216216 C239.424865,3.02995132 236.788427,0.393513514 233.536216,0.393513514 C230.284005,0.393513514 227.647568,3.02995132 227.647568,6.28216216 C227.647568,9.53437301 230.284005,12.1708108 233.536216,12.1708108" id="Path"></path>
      <path d="M233.536216,12.5643243 C237.005759,12.5643243 239.818378,9.75170452 239.818378,6.28216216 C239.818378,2.8126198 237.005759,0 233.536216,0 C230.066674,0 227.254054,2.8126198 227.254054,6.28216216 C227.254054,9.75170452 230.066674,12.5643243 233.536216,12.5643243 Z M233.536216,11.7772973 C230.501337,11.7772973 228.041081,9.3170415 228.041081,6.28216216 C228.041081,3.24728283 230.501337,0.787027027 233.536216,0.787027027 C236.571096,0.787027027 239.031351,3.24728283 239.031351,6.28216216 C239.031351,9.3170415 236.571096,11.7772973 233.536216,11.7772973 Z" id="Oval"></path>
      <path d="M6.29621622,57.6216216 C9.54842706,57.6216216 12.1848649,54.9851838 12.1848649,51.732973 C12.1848649,48.4807621 9.54842706,45.8443243 6.29621622,45.8443243 C3.04400537,45.8443243 0.407567568,48.4807621 0.407567568,51.732973 C0.415294128,54.981978 3.0472112,57.6138951 6.29621622,57.6216216" id="Path"></path>
      <path d="M6.28216216,58.0151351 C9.75170452,58.0151351 12.5643243,55.2025153 12.5643243,51.732973 C12.5643243,48.2634306 9.75170452,45.4508108 6.28216216,45.4508108 C2.8126198,45.4508108 0,48.2634306 0,51.732973 C0,55.2025153 2.8126198,58.0151351 6.28216216,58.0151351 Z M6.28216216,57.2281081 C3.24728283,57.2281081 0.787027027,54.7678523 0.787027027,51.732973 C0.787027027,48.6980936 3.24728283,46.2378378 6.28216216,46.2378378 C9.3170415,46.2378378 11.7772973,48.6980936 11.7772973,51.732973 C11.7772973,54.7678523 9.3170415,57.2281081 6.28216216,57.2281081 Z" id="Oval"></path>
      <path d="M125.797838,51.732973 C125.797838,48.4807621 123.1614,45.8443243 119.909189,45.8443243 C116.656978,45.8443243 114.020541,48.4807621 114.020541,51.732973 C114.028818,54.9821979 116.659964,57.6133446 119.908187,57.6216203 C123.1614,57.6216216 125.797838,54.9851838 125.797838,51.732973 Z M114.807566,51.7319704 C114.807568,48.9154252 117.091641,46.6313514 119.909189,46.6313514 C122.726737,46.6313514 125.010811,48.9154252 125.010811,51.732973 C125.010811,54.5503537 122.727008,56.8343237 119.90969,56.8345946 C117.094795,56.8271528 114.814738,54.5469293 114.807566,51.7319704 Z" id="Path"></path>
      <path d="M233.536216,57.6216216 C236.788427,57.6216216 239.424865,54.9851838 239.424865,51.732973 C239.424865,48.4807621 236.788427,45.8443243 233.536216,45.8443243 C230.284005,45.8443243 227.647568,48.4807621 227.647568,51.732973 C227.647568,54.9851838 230.284005,57.6216216 233.536216,57.6216216" id="Path"></path>
      <path d="M233.536216,58.0151351 C237.005759,58.0151351 239.818378,55.2025153 239.818378,51.732973 C239.818378,48.2634306 237.005759,45.4508108 233.536216,45.4508108 C230.066674,45.4508108 227.254054,48.2634306 227.254054,51.732973 C227.254054,55.2025153 230.066674,58.0151351 233.536216,58.0151351 Z M233.536216,57.2281081 C230.501337,57.2281081 228.041081,54.7678523 228.041081,51.732973 C228.041081,48.6980936 230.501337,46.2378378 233.536216,46.2378378 C236.571096,46.2378378 239.031351,48.6980936 239.031351,51.732973 C239.031351,54.7678523 236.571096,57.2281081 233.536216,57.2281081 Z" id="Oval"></path>
      <path d="M51.747027,12.1708108 C54.9992379,12.1708108 57.6356757,9.53437301 57.6356757,6.28216216 C57.6356757,3.02995132 54.9992379,0.393513514 51.747027,0.393513514 C48.4948162,0.393513514 45.8583784,3.02995132 45.8583784,6.28216216 C45.8661049,9.53116718 48.498022,12.1630843 51.747027,12.1708108" id="Path"></path>
      <path d="M51.732973,12.5643243 C55.2025153,12.5643243 58.0151351,9.75170452 58.0151351,6.28216216 C58.0151351,2.8126198 55.2025153,0 51.732973,0 C48.2634306,0 45.4508108,2.8126198 45.4508108,6.28216216 C45.4508108,9.75170452 48.2634306,12.5643243 51.732973,12.5643243 Z M51.732973,11.7772973 C48.6980936,11.7772973 46.2378378,9.3170415 46.2378378,6.28216216 C46.2378378,3.24728283 48.6980936,0.787027027 51.732973,0.787027027 C54.7678523,0.787027027 57.2281081,3.24728283 57.2281081,6.28216216 C57.2281081,9.3170415 54.7678523,11.7772973 51.732973,11.7772973 Z" id="Oval"></path>
      <path d="M171.248649,6.28216216 C171.248649,3.02995132 168.612211,0.393513514 165.36,0.393513514 C162.107789,0.393513514 159.471351,3.02995132 159.471351,6.28216216 C159.479628,9.53138706 162.110775,12.1625338 165.358997,12.1708095 C168.612211,12.1708108 171.248649,9.53437301 171.248649,6.28216216 Z M160.258377,6.28115958 C160.258378,3.46461434 162.542452,1.18054054 165.36,1.18054054 C168.177548,1.18054054 170.461622,3.46461434 170.461622,6.28216216 C170.461622,9.09954288 168.177819,11.3835129 165.360501,11.3837838 C162.545606,11.376342 160.265549,9.09611849 160.258377,6.28115958 Z" id="Path"></path>
      <path d="M51.747027,57.6216216 C54.9992379,57.6216216 57.6356757,54.9851838 57.6356757,51.732973 C57.6356757,48.4807621 54.9992379,45.8443243 51.747027,45.8443243 C48.4948162,45.8443243 45.8583784,48.4807621 45.8583784,51.732973 C45.8661049,54.981978 48.498022,57.6138951 51.747027,57.6216216" id="Path"></path>
      <path d="M51.732973,58.0151351 C55.2025153,58.0151351 58.0151351,55.2025153 58.0151351,51.732973 C58.0151351,48.2634306 55.2025153,45.4508108 51.732973,45.4508108 C48.2634306,45.4508108 45.4508108,48.2634306 45.4508108,51.732973 C45.4508108,55.2025153 48.2634306,58.0151351 51.732973,58.0151351 Z M51.732973,57.2281081 C48.6980936,57.2281081 46.2378378,54.7678523 46.2378378,51.732973 C46.2378378,48.6980936 48.6980936,46.2378378 51.732973,46.2378378 C54.7678523,46.2378378 57.2281081,48.6980936 57.2281081,51.732973 C57.2281081,54.7678523 54.7678523,57.2281081 51.732973,57.2281081 Z" id="Oval"></path>
      <path d="M165.36,57.6216216 C168.612211,57.6216216 171.248649,54.9851838 171.248649,51.732973 C171.248649,48.4807621 168.612211,45.8443243 165.36,45.8443243 C162.107789,45.8443243 159.471351,48.4807621 159.471351,51.732973 C159.471351,54.9851838 162.107789,57.6216216 165.36,57.6216216" id="Path"></path>
      <path d="M165.36,58.0151351 C168.829542,58.0151351 171.642162,55.2025153 171.642162,51.732973 C171.642162,48.2634306 168.829542,45.4508108 165.36,45.4508108 C161.890458,45.4508108 159.077838,48.2634306 159.077838,51.732973 C159.077838,55.2025153 161.890458,58.0151351 165.36,58.0151351 Z M165.36,57.2281081 C162.325121,57.2281081 159.864865,54.7678523 159.864865,51.732973 C159.864865,48.6980936 162.325121,46.2378378 165.36,46.2378378 C168.394879,46.2378378 170.855135,48.6980936 170.855135,51.732973 C170.855135,54.7678523 168.394879,57.2281081 165.36,57.2281081 Z" id="Oval"></path>
      <path d="M34.8962077,28.9975516 C34.8906694,25.7473083 32.2528071,23.1161752 29.0025602,23.118939 C25.7523134,23.1217028 23.1189295,25.7573183 23.1189189,29.0075663 C23.1272215,32.2626621 25.7675217,34.8962268 29.0216229,34.8962162 C32.2728654,34.8879023 34.9017459,32.2477949 34.8962077,28.9975516 Z M23.9059447,29.0065637 C23.9059552,26.1917215 26.1873832,23.9083602 29.0032295,23.9059658 C31.8190758,23.9035713 34.1043837,26.1830494 34.1091818,28.9988927 C34.1139795,31.8145684 31.8367243,34.1017167 29.0211178,34.1091892 C26.2011274,34.1089272 23.9131387,31.8266081 23.9059447,29.0065637 Z" id="Path"></path>
      <path d="M142.634595,34.8962162 C145.886805,34.8962162 148.523243,32.2597784 148.523243,29.0075676 C148.523243,25.7553567 145.886805,23.1189189 142.634595,23.1189189 C139.382384,23.1189189 136.745946,25.7553567 136.745946,29.0075676 C136.745946,32.2597784 139.382384,34.8962162 142.634595,34.8962162" id="Path"></path>
      <path d="M142.634595,35.2897297 C146.104137,35.2897297 148.916757,32.4771099 148.916757,29.0075676 C148.916757,25.5380252 146.104137,22.7254054 142.634595,22.7254054 C139.165052,22.7254054 136.352432,25.5380252 136.352432,29.0075676 C136.352432,32.4771099 139.165052,35.2897297 142.634595,35.2897297 Z M142.634595,34.5027027 C139.599715,34.5027027 137.139459,32.0424469 137.139459,29.0075676 C137.139459,25.9726882 139.599715,23.5124324 142.634595,23.5124324 C145.669474,23.5124324 148.12973,25.9726882 148.12973,29.0075676 C148.12973,32.0424469 145.669474,34.5027027 142.634595,34.5027027 Z" id="Oval"></path>
      <path d="M34.8962077,74.4483624 C34.8906694,71.1981191 32.2528071,68.566986 29.0025602,68.5697498 C25.7523134,68.5725136 23.1189295,71.2081291 23.1189189,74.4583771 C23.1272215,77.7134729 25.7675217,80.3470376 29.0216229,80.347027 C32.2728654,80.3387131 34.9017459,77.6986057 34.8962077,74.4483624 Z M23.9059447,74.4573745 C23.9059552,71.6425323 26.1873832,69.359171 29.0032295,69.3567766 C31.8190758,69.3543822 34.1043837,71.6338603 34.1091818,74.4497035 C34.1139795,77.2653792 31.8367243,79.5525275 29.0211178,79.56 C26.2011274,79.559738 23.9131387,77.2774189 23.9059447,74.4573745 Z" id="Path"></path>
      <path d="M142.634595,80.347027 C145.886805,80.347027 148.523243,77.7105892 148.523243,74.4583784 C148.523243,71.2061675 145.886805,68.5697297 142.634595,68.5697297 C139.382384,68.5697297 136.745946,71.2061675 136.745946,74.4583784 C136.745946,77.7105892 139.382384,80.347027 142.634595,80.347027" id="Path"></path>
      <path d="M142.634595,80.7405405 C146.104137,80.7405405 148.916757,77.9279207 148.916757,74.4583784 C148.916757,70.988836 146.104137,68.1762162 142.634595,68.1762162 C139.165052,68.1762162 136.352432,70.988836 136.352432,74.4583784 C136.352432,77.9279207 139.165052,80.7405405 142.634595,80.7405405 Z M142.634595,79.9535135 C139.599715,79.9535135 137.139459,77.4932577 137.139459,74.4583784 C137.139459,71.423499 139.599715,68.9632432 142.634595,68.9632432 C145.669474,68.9632432 148.12973,71.423499 148.12973,74.4583784 C148.12973,77.4932577 145.669474,79.9535135 142.634595,79.9535135 Z" id="Oval"></path>
      <path d="M29.0216216,103.072432 C32.2738325,103.072432 34.9102703,100.435995 34.9102703,97.1837838 C34.9102703,93.9315729 32.2738325,91.2951351 29.0216216,91.2951351 C25.7694108,91.2951351 23.132973,93.9315729 23.132973,97.1837838 C23.1406995,100.432789 25.7726166,103.064706 29.0216216,103.072432" id="Path"></path>
      <path d="M29.0075676,103.465946 C32.4771099,103.465946 35.2897297,100.653326 35.2897297,97.1837838 C35.2897297,93.7142414 32.4771099,90.9016216 29.0075676,90.9016216 C25.5380252,90.9016216 22.7254054,93.7142414 22.7254054,97.1837838 C22.7254054,100.653326 25.5380252,103.465946 29.0075676,103.465946 Z M29.0075676,102.678919 C25.9726882,102.678919 23.5124324,100.218663 23.5124324,97.1837838 C23.5124324,94.1489045 25.9726882,91.6886486 29.0075676,91.6886486 C32.0424469,91.6886486 34.5027027,94.1489045 34.5027027,97.1837838 C34.5027027,100.218663 32.0424469,102.678919 29.0075676,102.678919 Z" id="Oval"></path>
      <path d="M142.634595,103.072432 C145.886805,103.072432 148.523243,100.435995 148.523243,97.1837838 C148.523243,93.9315729 145.886805,91.2951351 142.634595,91.2951351 C139.382384,91.2951351 136.745946,93.9315729 136.745946,97.1837838 C136.745946,100.435995 139.382384,103.072432 142.634595,103.072432" id="Path"></path>
      <path d="M142.634595,103.465946 C146.104137,103.465946 148.916757,100.653326 148.916757,97.1837838 C148.916757,93.7142414 146.104137,90.9016216 142.634595,90.9016216 C139.165052,90.9016216 136.352432,93.7142414 136.352432,97.1837838 C136.352432,100.653326 139.165052,103.465946 142.634595,103.465946 Z M142.634595,102.678919 C139.599715,102.678919 137.139459,100.218663 137.139459,97.1837838 C137.139459,94.1489045 139.599715,91.6886486 142.634595,91.6886486 C145.669474,91.6886486 148.12973,94.1489045 148.12973,97.1837838 C148.12973,100.218663 145.669474,102.678919 142.634595,102.678919 Z" id="Oval"></path>
      <path d="M80.3470185,28.9975516 C80.3414802,25.7473083 77.7036179,23.1161752 74.4533711,23.118939 C71.2031242,23.1217028 68.5697404,25.7573183 68.5697297,29.0075663 C68.5780323,32.2626621 71.2183325,34.8962268 74.4724337,34.8962162 C77.7236762,34.8879023 80.3525567,32.2477949 80.3470185,28.9975516 Z M69.3567555,29.0065637 C69.356766,26.1917215 71.638194,23.9083602 74.4540403,23.9059658 C77.2698866,23.9035713 79.5551946,26.1830494 79.5599926,28.9988927 C79.5647903,31.8145684 77.2875351,34.1017167 74.4719286,34.1091892 C71.6519382,34.1089272 69.3639495,31.8266081 69.3567555,29.0065637 Z" id="Path"></path>
      <path d="M193.974054,29.0075676 C193.974054,25.7553567 191.337616,23.1189189 188.085405,23.1189189 C184.833195,23.1189189 182.196757,25.7553567 182.196757,29.0075676 C182.205034,32.2567925 184.836181,34.8879392 188.084403,34.8962149 C191.337616,34.8962162 193.974054,32.2597784 193.974054,29.0075676 Z M182.983783,29.006565 C182.983784,26.1900197 185.267858,23.9059459 188.085405,23.9059459 C190.902953,23.9059459 193.187027,26.1900197 193.187027,29.0075676 C193.187027,31.8249483 190.903224,34.1089183 188.085907,34.1091892 C185.271011,34.1017474 182.990954,31.8215239 182.983783,29.006565 Z" id="Path"></path>
      <path d="M80.3470185,74.4483624 C80.3414802,71.1981191 77.7036179,68.566986 74.4533711,68.5697498 C71.2031242,68.5725136 68.5697404,71.2081291 68.5697297,74.4583771 C68.5780323,77.7134729 71.2183325,80.3470376 74.4724337,80.347027 C77.7236762,80.3387131 80.3525567,77.6986057 80.3470185,74.4483624 Z M69.3567555,74.4573745 C69.356766,71.6425323 71.638194,69.359171 74.4540403,69.3567766 C77.2698866,69.3543822 79.5551946,71.6338603 79.5599926,74.4497035 C79.5647903,77.2653792 77.2875351,79.5525275 74.4719286,79.56 C71.6519382,79.559738 69.3639495,77.2774189 69.3567555,74.4573745 Z" id="Path"></path>
      <path d="M193.974054,74.4583784 C193.974054,71.2061675 191.337616,68.5697297 188.085405,68.5697297 C184.833195,68.5697297 182.196757,71.2061675 182.196757,74.4583784 C182.205034,77.7076033 184.836181,80.33875 188.084403,80.3470257 C191.337616,80.347027 193.974054,77.7105892 193.974054,74.4583784 Z M182.983783,74.4573758 C182.983784,71.6408306 185.267858,69.3567568 188.085405,69.3567568 C190.902953,69.3567568 193.187027,71.6408306 193.187027,74.4583784 C193.187027,77.2757591 190.903224,79.5597291 188.085907,79.56 C185.271011,79.5525582 182.990954,77.2723347 182.983783,74.4573758 Z" id="Path"></path>
      <path d="M74.4724324,103.072432 C77.7228025,103.064675 80.3521881,100.424812 80.3470196,97.1744368 C80.341851,93.9240616 77.7040832,91.2925741 74.4537049,91.2951538 C71.2033266,91.2977334 68.569739,93.9334045 68.5697297,97.1837838 C68.5774785,100.438267 71.2179403,103.072442 74.4724324,103.072432" id="Path"></path>
      <path d="M74.4583784,103.465946 C77.9279207,103.465946 80.7405405,100.653326 80.7405405,97.1837838 C80.7405405,93.7142414 77.9279207,90.9016216 74.4583784,90.9016216 C70.988836,90.9016216 68.1762162,93.7142414 68.1762162,97.1837838 C68.1762162,100.653326 70.988836,103.465946 74.4583784,103.465946 Z M74.4583784,102.678919 C71.423499,102.678919 68.9632432,100.218663 68.9632432,97.1837838 C68.9632432,94.1489045 71.423499,91.6886486 74.4583784,91.6886486 C77.4932577,91.6886486 79.9535135,94.1489045 79.9535135,97.1837838 C79.9535135,100.218663 77.4932577,102.678919 74.4583784,102.678919 Z" id="Oval"></path>
      <path d="M193.974054,97.1837838 C193.974054,93.9315729 191.337616,91.2951351 188.085405,91.2951351 C184.833195,91.2951351 182.196757,93.9315729 182.196757,97.1837838 C182.205034,100.433009 184.836181,103.064155 188.084403,103.072431 C191.337616,103.072432 193.974054,100.435995 193.974054,97.1837838 Z M182.983783,97.1827812 C182.983784,94.366236 185.267858,92.0821622 188.085405,92.0821622 C190.902953,92.0821622 193.187027,94.366236 193.187027,97.1837838 C193.187027,100.001165 190.903224,102.285134 188.085907,102.285405 C185.271011,102.277964 182.990954,99.9977401 182.983783,97.1827812 Z" id="Path"></path>
      <path d="M103.072432,29.0075676 C103.072432,25.7553567 100.435995,23.1189189 97.1837838,23.1189189 C93.9315729,23.1189189 91.2951351,25.7553567 91.2951351,29.0075676 C91.3034122,32.2567925 93.9345589,34.8879392 97.1827812,34.8962149 C100.435995,34.8962162 103.072432,32.2597784 103.072432,29.0075676 Z M92.0821609,29.006565 C92.0821622,26.1900197 94.366236,23.9059459 97.1837838,23.9059459 C100.001332,23.9059459 102.285405,26.1900197 102.285405,29.0075676 C102.285405,31.8249483 100.001603,34.1089183 97.1842851,34.1091892 C94.3693897,34.1017474 92.0893328,31.8215239 92.0821609,29.006565 Z" id="Path"></path>
      <path d="M216.699459,29.0075676 C216.699459,25.7553567 214.063022,23.1189189 210.810811,23.1189189 C207.5586,23.1189189 204.922162,25.7553567 204.922162,29.0075676 C204.930439,32.2567925 207.561586,34.8879392 210.809808,34.8962149 C214.063022,34.8962162 216.699459,32.2597784 216.699459,29.0075676 Z M205.709188,29.006565 C205.709189,26.1900197 207.993263,23.9059459 210.810811,23.9059459 C213.628359,23.9059459 215.912432,26.1900197 215.912432,29.0075676 C215.912432,31.8249483 213.62863,34.1089183 210.811312,34.1091892 C207.996417,34.1017474 205.71636,31.8215239 205.709188,29.006565 Z" id="Path"></path>
      <path d="M97.1837838,80.347027 C100.435995,80.347027 103.072432,77.7105892 103.072432,74.4583784 C103.072432,71.2061675 100.435995,68.5697297 97.1837838,68.5697297 C93.9315729,68.5697297 91.2951351,71.2061675 91.2951351,74.4583784 C91.3028617,77.7073834 93.9347788,80.3393005 97.1837838,80.347027" id="Path"></path>
      <path d="M97.1837838,80.7405405 C100.653326,80.7405405 103.465946,77.9279207 103.465946,74.4583784 C103.465946,70.988836 100.653326,68.1762162 97.1837838,68.1762162 C93.7142414,68.1762162 90.9016216,70.988836 90.9016216,74.4583784 C90.9016216,77.9279207 93.7142414,80.7405405 97.1837838,80.7405405 Z M97.1837838,79.9535135 C94.1489045,79.9535135 91.6886486,77.4932577 91.6886486,74.4583784 C91.6886486,71.423499 94.1489045,68.9632432 97.1837838,68.9632432 C100.218663,68.9632432 102.678919,71.423499 102.678919,74.4583784 C102.678919,77.4932577 100.218663,79.9535135 97.1837838,79.9535135 Z" id="Oval"></path>
      <path d="M216.699459,74.4583784 C216.699459,71.2061675 214.063022,68.5697297 210.810811,68.5697297 C207.5586,68.5697297 204.922162,71.2061675 204.922162,74.4583784 C204.930439,77.7076033 207.561586,80.33875 210.809808,80.3470257 C214.063022,80.347027 216.699459,77.7105892 216.699459,74.4583784 Z M205.709188,74.4573758 C205.709189,71.6408306 207.993263,69.3567568 210.810811,69.3567568 C213.628359,69.3567568 215.912432,71.6408306 215.912432,74.4583784 C215.912432,77.2757591 213.62863,79.5597291 210.811312,79.56 C207.996417,79.5525582 205.71636,77.2723347 205.709188,74.4573758 Z" id="Path"></path>
      <path d="M97.1837838,103.072432 C100.435995,103.072432 103.072432,100.435995 103.072432,97.1837838 C103.072432,93.9315729 100.435995,91.2951351 97.1837838,91.2951351 C93.9315729,91.2951351 91.2951351,93.9315729 91.2951351,97.1837838 C91.3028617,100.432789 93.9347788,103.064706 97.1837838,103.072432" id="Path"></path>
      <path d="M97.1837838,103.465946 C100.653326,103.465946 103.465946,100.653326 103.465946,97.1837838 C103.465946,93.7142414 100.653326,90.9016216 97.1837838,90.9016216 C93.7142414,90.9016216 90.9016216,93.7142414 90.9016216,97.1837838 C90.9016216,100.653326 93.7142414,103.465946 97.1837838,103.465946 Z M97.1837838,102.678919 C94.1489045,102.678919 91.6886486,100.218663 91.6886486,97.1837838 C91.6886486,94.1489045 94.1489045,91.6886486 97.1837838,91.6886486 C100.218663,91.6886486 102.678919,94.1489045 102.678919,97.1837838 C102.678919,100.218663 100.218663,102.678919 97.1837838,102.678919 Z" id="Oval"></path>
      <path d="M216.699459,97.1837838 C216.699459,93.9315729 214.063022,91.2951351 210.810811,91.2951351 C207.5586,91.2951351 204.922162,93.9315729 204.922162,97.1837838 C204.930439,100.433009 207.561586,103.064155 210.809808,103.072431 C214.063022,103.072432 216.699459,100.435995 216.699459,97.1837838 Z M205.709188,97.1827812 C205.709189,94.366236 207.993263,92.0821622 210.810811,92.0821622 C213.628359,92.0821622 215.912432,94.366236 215.912432,97.1837838 C215.912432,100.001165 213.62863,102.285134 210.811312,102.285405 C207.996417,102.277964 205.71636,99.9977401 205.709188,97.1827812 Z" id="Path"></path>
      <path d="M29.0216216,12.1708108 C32.2738325,12.1708108 34.9102703,9.53437301 34.9102703,6.28216216 C34.9102703,3.02995132 32.2738325,0.393513514 29.0216216,0.393513514 C25.7694108,0.393513514 23.132973,3.02995132 23.132973,6.28216216 C23.1406995,9.53116718 25.7726166,12.1630843 29.0216216,12.1708108" id="Path"></path>
      <path d="M29.0075676,12.5643243 C32.4771099,12.5643243 35.2897297,9.75170452 35.2897297,6.28216216 C35.2897297,2.8126198 32.4771099,0 29.0075676,0 C25.5380252,0 22.7254054,2.8126198 22.7254054,6.28216216 C22.7254054,9.75170452 25.5380252,12.5643243 29.0075676,12.5643243 Z M29.0075676,11.7772973 C25.9726882,11.7772973 23.5124324,9.3170415 23.5124324,6.28216216 C23.5124324,3.24728283 25.9726882,0.787027027 29.0075676,0.787027027 C32.0424469,0.787027027 34.5027027,3.24728283 34.5027027,6.28216216 C34.5027027,9.3170415 32.0424469,11.7772973 29.0075676,11.7772973 Z" id="Oval"></path>
      <path d="M142.634595,12.1708108 C145.886805,12.1708108 148.523243,9.53437301 148.523243,6.28216216 C148.523243,3.02995132 145.886805,0.393513514 142.634595,0.393513514 C139.382384,0.393513514 136.745946,3.02995132 136.745946,6.28216216 C136.745946,9.53437301 139.382384,12.1708108 142.634595,12.1708108" id="Path"></path>
      <path d="M142.634595,12.5643243 C146.104137,12.5643243 148.916757,9.75170452 148.916757,6.28216216 C148.916757,2.8126198 146.104137,0 142.634595,0 C139.165052,0 136.352432,2.8126198 136.352432,6.28216216 C136.352432,9.75170452 139.165052,12.5643243 142.634595,12.5643243 Z M142.634595,11.7772973 C139.599715,11.7772973 137.139459,9.3170415 137.139459,6.28216216 C137.139459,3.24728283 139.599715,0.787027027 142.634595,0.787027027 C145.669474,0.787027027 148.12973,3.24728283 148.12973,6.28216216 C148.12973,9.3170415 145.669474,11.7772973 142.634595,11.7772973 Z" id="Oval"></path>
      <path d="M34.8962077,51.722957 C34.8906694,48.4727137 32.2528071,45.8415806 29.0025602,45.8443444 C25.7523134,45.8471082 23.1189295,48.4827237 23.1189189,51.7329717 C23.1272215,54.9880675 25.7675217,57.6216322 29.0216229,57.6216216 C32.2728654,57.6133077 34.9017459,54.9732003 34.8962077,51.722957 Z M23.9059447,51.7319691 C23.9059552,48.9171269 26.1873832,46.6337656 29.0032295,46.6313712 C31.8190758,46.6289768 34.1043837,48.9084548 34.1091818,51.7242981 C34.1139795,54.5399738 31.8367243,56.8271221 29.0211178,56.8345946 C26.2011274,56.8343326 23.9131387,54.5520135 23.9059447,51.7319691 Z" id="Path"></path>
      <path d="M142.634595,57.6216216 C145.886805,57.6216216 148.523243,54.9851838 148.523243,51.732973 C148.523243,48.4807621 145.886805,45.8443243 142.634595,45.8443243 C139.382384,45.8443243 136.745946,48.4807621 136.745946,51.732973 C136.745946,54.9851838 139.382384,57.6216216 142.634595,57.6216216" id="Path"></path>
      <path d="M142.634595,58.0151351 C146.104137,58.0151351 148.916757,55.2025153 148.916757,51.732973 C148.916757,48.2634306 146.104137,45.4508108 142.634595,45.4508108 C139.165052,45.4508108 136.352432,48.2634306 136.352432,51.732973 C136.352432,55.2025153 139.165052,58.0151351 142.634595,58.0151351 Z M142.634595,57.2281081 C139.599715,57.2281081 137.139459,54.7678523 137.139459,51.732973 C137.139459,48.6980936 139.599715,46.2378378 142.634595,46.2378378 C145.669474,46.2378378 148.12973,48.6980936 148.12973,51.732973 C148.12973,54.7678523 145.669474,57.2281081 142.634595,57.2281081 Z" id="Oval"></path>
      <path d="M74.4724324,12.1708108 C77.7228025,12.1630533 80.3521881,9.52319037 80.3470196,6.27281515 C80.341851,3.02243993 77.7040832,0.390952485 74.4537049,0.393532139 C71.2033266,0.396111793 68.569739,3.03178283 68.5697297,6.28216216 C68.5774785,9.53664505 71.2179403,12.17082 74.4724324,12.1708108" id="Path"></path>
      <path d="M74.4583784,12.5643243 C77.9279207,12.5643243 80.7405405,9.75170452 80.7405405,6.28216216 C80.7405405,2.8126198 77.9279207,0 74.4583784,0 C70.988836,0 68.1762162,2.8126198 68.1762162,6.28216216 C68.1762162,9.75170452 70.988836,12.5643243 74.4583784,12.5643243 Z M74.4583784,11.7772973 C71.423499,11.7772973 68.9632432,9.3170415 68.9632432,6.28216216 C68.9632432,3.24728283 71.423499,0.787027027 74.4583784,0.787027027 C77.4932577,0.787027027 79.9535135,3.24728283 79.9535135,6.28216216 C79.9535135,9.3170415 77.4932577,11.7772973 74.4583784,11.7772973 Z" id="Oval"></path>
      <path d="M193.974054,6.28216216 C193.974054,3.02995132 191.337616,0.393513514 188.085405,0.393513514 C184.833195,0.393513514 182.196757,3.02995132 182.196757,6.28216216 C182.205034,9.53138706 184.836181,12.1625338 188.084403,12.1708095 C191.337616,12.1708108 193.974054,9.53437301 193.974054,6.28216216 Z M182.983783,6.28115958 C182.983784,3.46461434 185.267858,1.18054054 188.085405,1.18054054 C190.902953,1.18054054 193.187027,3.46461434 193.187027,6.28216216 C193.187027,9.09954288 190.903224,11.3835129 188.085907,11.3837838 C185.271011,11.376342 182.990954,9.09611849 182.983783,6.28115958 Z" id="Path"></path>
      <path d="M74.4724324,57.6216216 C77.7228025,57.6138642 80.3521881,54.9740012 80.3470196,51.723626 C80.341851,48.4732507 77.7040832,45.8417633 74.4537049,45.8443429 C71.2033266,45.8469226 68.569739,48.4825936 68.5697297,51.732973 C68.5774785,54.9874559 71.2179403,57.6216308 74.4724324,57.6216216" id="Path"></path>
      <path d="M74.4583784,58.0151351 C77.9279207,58.0151351 80.7405405,55.2025153 80.7405405,51.732973 C80.7405405,48.2634306 77.9279207,45.4508108 74.4583784,45.4508108 C70.988836,45.4508108 68.1762162,48.2634306 68.1762162,51.732973 C68.1762162,55.2025153 70.988836,58.0151351 74.4583784,58.0151351 Z M74.4583784,57.2281081 C71.423499,57.2281081 68.9632432,54.7678523 68.9632432,51.732973 C68.9632432,48.6980936 71.423499,46.2378378 74.4583784,46.2378378 C77.4932577,46.2378378 79.9535135,48.6980936 79.9535135,51.732973 C79.9535135,54.7678523 77.4932577,57.2281081 74.4583784,57.2281081 Z" id="Oval"></path>
      <path d="M188.085405,57.6216216 C191.337616,57.6216216 193.974054,54.9851838 193.974054,51.732973 C193.974054,48.4807621 191.337616,45.8443243 188.085405,45.8443243 C184.833195,45.8443243 182.196757,48.4807621 182.196757,51.732973 C182.196757,54.9851838 184.833195,57.6216216 188.085405,57.6216216" id="Path"></path>
      <path d="M188.085405,58.0151351 C191.554948,58.0151351 194.367568,55.2025153 194.367568,51.732973 C194.367568,48.2634306 191.554948,45.4508108 188.085405,45.4508108 C184.615863,45.4508108 181.803243,48.2634306 181.803243,51.732973 C181.803243,55.2025153 184.615863,58.0151351 188.085405,58.0151351 Z M188.085405,57.2281081 C185.050526,57.2281081 182.59027,54.7678523 182.59027,51.732973 C182.59027,48.6980936 185.050526,46.2378378 188.085405,46.2378378 C191.120285,46.2378378 193.580541,48.6980936 193.580541,51.732973 C193.580541,54.7678523 191.120285,57.2281081 188.085405,57.2281081 Z" id="Oval"></path>
      <path d="M97.1837838,12.1708108 C100.435995,12.1708108 103.072432,9.53437301 103.072432,6.28216216 C103.072432,3.02995132 100.435995,0.393513514 97.1837838,0.393513514 C93.9315729,0.393513514 91.2951351,3.02995132 91.2951351,6.28216216 C91.3028617,9.53116718 93.9347788,12.1630843 97.1837838,12.1708108" id="Path"></path>
      <path d="M97.1837838,12.5643243 C100.653326,12.5643243 103.465946,9.75170452 103.465946,6.28216216 C103.465946,2.8126198 100.653326,0 97.1837838,0 C93.7142414,0 90.9016216,2.8126198 90.9016216,6.28216216 C90.9016216,9.75170452 93.7142414,12.5643243 97.1837838,12.5643243 Z M97.1837838,11.7772973 C94.1489045,11.7772973 91.6886486,9.3170415 91.6886486,6.28216216 C91.6886486,3.24728283 94.1489045,0.787027027 97.1837838,0.787027027 C100.218663,0.787027027 102.678919,3.24728283 102.678919,6.28216216 C102.678919,9.3170415 100.218663,11.7772973 97.1837838,11.7772973 Z" id="Oval"></path>
      <path d="M216.699459,6.28216216 C216.699459,3.02995132 214.063022,0.393513514 210.810811,0.393513514 C207.5586,0.393513514 204.922162,3.02995132 204.922162,6.28216216 C204.930439,9.53138706 207.561586,12.1625338 210.809808,12.1708095 C214.063022,12.1708108 216.699459,9.53437301 216.699459,6.28216216 Z M205.709188,6.28115958 C205.709189,3.46461434 207.993263,1.18054054 210.810811,1.18054054 C213.628359,1.18054054 215.912432,3.46461434 215.912432,6.28216216 C215.912432,9.09954288 213.62863,11.3835129 210.811312,11.3837838 C207.996417,11.376342 205.71636,9.09611849 205.709188,6.28115958 Z" id="Path"></path>
      <path d="M97.1837838,57.6216216 C100.435995,57.6216216 103.072432,54.9851838 103.072432,51.732973 C103.072432,48.4807621 100.435995,45.8443243 97.1837838,45.8443243 C93.9315729,45.8443243 91.2951351,48.4807621 91.2951351,51.732973 C91.3028617,54.981978 93.9347788,57.6138951 97.1837838,57.6216216" id="Path"></path>
      <path d="M97.1837838,58.0151351 C100.653326,58.0151351 103.465946,55.2025153 103.465946,51.732973 C103.465946,48.2634306 100.653326,45.4508108 97.1837838,45.4508108 C93.7142414,45.4508108 90.9016216,48.2634306 90.9016216,51.732973 C90.9016216,55.2025153 93.7142414,58.0151351 97.1837838,58.0151351 Z M97.1837838,57.2281081 C94.1489045,57.2281081 91.6886486,54.7678523 91.6886486,51.732973 C91.6886486,48.6980936 94.1489045,46.2378378 97.1837838,46.2378378 C100.218663,46.2378378 102.678919,48.6980936 102.678919,51.732973 C102.678919,54.7678523 100.218663,57.2281081 97.1837838,57.2281081 Z" id="Oval"></path>
      <path d="M210.810811,57.6216216 C214.063022,57.6216216 216.699459,54.9851838 216.699459,51.732973 C216.699459,48.4807621 214.063022,45.8443243 210.810811,45.8443243 C207.5586,45.8443243 204.922162,48.4807621 204.922162,51.732973 C204.922162,54.9851838 207.5586,57.6216216 210.810811,57.6216216" id="Path"></path>
      <path d="M210.810811,58.0151351 C214.280353,58.0151351 217.092973,55.2025153 217.092973,51.732973 C217.092973,48.2634306 214.280353,45.4508108 210.810811,45.4508108 C207.341268,45.4508108 204.528649,48.2634306 204.528649,51.732973 C204.528649,55.2025153 207.341268,58.0151351 210.810811,58.0151351 Z M210.810811,57.2281081 C207.775931,57.2281081 205.315676,54.7678523 205.315676,51.732973 C205.315676,48.6980936 207.775931,46.2378378 210.810811,46.2378378 C213.84569,46.2378378 216.305946,48.6980936 216.305946,51.732973 C216.305946,54.7678523 213.84569,57.2281081 210.810811,57.2281081 Z" id="Oval"></path>
      <path d="M0.407567568,29.0075676 C0.407567568,32.2597784 3.04400537,34.8962162 6.29621622,34.8962162 C9.54842706,34.8962162 12.1848649,32.2597784 12.1848649,29.0075676 C12.1848649,25.7553567 9.54842706,23.1189189 6.29621622,23.1189189 C3.04400537,23.1189189 0.407567568,25.7553567 0.407567568,29.0075676" id="Path"></path>
      <path d="M6.28216216,35.2897297 C9.75170452,35.2897297 12.5643243,32.4771099 12.5643243,29.0075676 C12.5643243,25.5380252 9.75170452,22.7254054 6.28216216,22.7254054 C2.8126198,22.7254054 0,25.5380252 0,29.0075676 C0,32.4771099 2.8126198,35.2897297 6.28216216,35.2897297 Z M6.28216216,34.5027027 C3.24728283,34.5027027 0.787027027,32.0424469 0.787027027,29.0075676 C0.787027027,25.9726882 3.24728283,23.5124324 6.28216216,23.5124324 C9.3170415,23.5124324 11.7772973,25.9726882 11.7772973,29.0075676 C11.7772973,32.0424469 9.3170415,34.5027027 6.28216216,34.5027027 Z" id="Oval"></path>
      <path d="M113.97838,29.0065637 C113.972286,31.3953449 115.406563,33.5523016 117.611763,34.4706742 C119.816962,35.3890468 122.358286,34.8877602 124.04957,33.2007899 C125.740855,31.5138196 126.248631,28.9737848 125.335894,26.7662468 C124.423157,24.5587087 122.269871,23.1189267 119.881082,23.1189189 C116.626981,23.1189083 113.986681,25.752473 113.97838,29.0065637 Z M114.765404,29.0085714 C114.772599,26.1883595 117.060859,23.9059368 119.88108,23.9059459 C121.951364,23.9059527 123.817545,25.1537638 124.608584,27.0669634 C125.399622,28.9801631 124.959549,31.1815266 123.493769,32.6435675 C122.027989,34.1056084 119.825509,34.5400568 117.914336,33.7441339 C116.003163,32.9482109 114.760123,31.0788485 114.765404,29.0085714 Z" id="Path"></path>
      <path d="M227.647568,29.0075676 C227.647568,32.2597784 230.284005,34.8962162 233.536216,34.8962162 C236.788427,34.8962162 239.424865,32.2597784 239.424865,29.0075676 C239.424865,25.7553567 236.788427,23.1189189 233.536216,23.1189189 C230.287211,23.1266455 227.655294,25.7585625 227.647568,29.0075676" id="Path"></path>
      <path d="M233.536216,35.2897297 C237.005759,35.2897297 239.818378,32.4771099 239.818378,29.0075676 C239.818378,25.5380252 237.005759,22.7254054 233.536216,22.7254054 C230.066674,22.7254054 227.254054,25.5380252 227.254054,29.0075676 C227.254054,32.4771099 230.066674,35.2897297 233.536216,35.2897297 Z M233.536216,34.5027027 C230.501337,34.5027027 228.041081,32.0424469 228.041081,29.0075676 C228.041081,25.9726882 230.501337,23.5124324 233.536216,23.5124324 C236.571096,23.5124324 239.031351,25.9726882 239.031351,29.0075676 C239.031351,32.0424469 236.571096,34.5027027 233.536216,34.5027027 Z" id="Oval"></path>
      <path d="M0.407567568,74.4583784 C0.407567568,77.7105892 3.04400537,80.347027 6.29621622,80.347027 C9.54842706,80.347027 12.1848649,77.7105892 12.1848649,74.4583784 C12.1848649,71.2061675 9.54842706,68.5697297 6.29621622,68.5697297 C3.04400537,68.5697297 0.407567568,71.2061675 0.407567568,74.4583784" id="Path"></path>
      <path d="M6.28216216,80.7405405 C9.75170452,80.7405405 12.5643243,77.9279207 12.5643243,74.4583784 C12.5643243,70.988836 9.75170452,68.1762162 6.28216216,68.1762162 C2.8126198,68.1762162 0,70.988836 0,74.4583784 C0,77.9279207 2.8126198,80.7405405 6.28216216,80.7405405 Z M6.28216216,79.9535135 C3.24728283,79.9535135 0.787027027,77.4932577 0.787027027,74.4583784 C0.787027027,71.423499 3.24728283,68.9632432 6.28216216,68.9632432 C9.3170415,68.9632432 11.7772973,71.423499 11.7772973,74.4583784 C11.7772973,77.4932577 9.3170415,79.9535135 6.28216216,79.9535135 Z" id="Oval"></path>
      <path d="M119.831685,80.3737809 C123.077668,80.4009371 125.736451,77.8019774 125.783158,74.5562165 C125.829864,71.3104556 123.246966,68.6360675 120.001545,68.5698117 C118.417893,68.5374812 116.887697,69.1429977 115.754982,70.2502269 C114.622267,71.357456 113.98209,72.8734785 113.978379,74.4574565 C113.970775,77.7035445 116.585702,80.3466246 119.831685,80.3737809 Z M116.305126,70.8130346 C117.286813,69.853436 118.612983,69.328655 119.985482,69.3566748 C122.798179,69.4140965 125.036691,71.7318995 124.996212,74.5448922 C124.955733,77.357885 122.651455,79.6103168 119.838269,79.5867814 C117.025084,79.563246 114.758814,77.2725766 114.765404,74.4593003 C114.76862,73.0865193 115.32344,71.7726332 116.305126,70.8130346 Z" id="Path"></path>
      <path d="M227.647568,74.4583784 C227.647568,77.7105892 230.284005,80.347027 233.536216,80.347027 C236.788427,80.347027 239.424865,77.7105892 239.424865,74.4583784 C239.424865,71.2061675 236.788427,68.5697297 233.536216,68.5697297 C230.287211,68.5774563 227.655294,71.2093734 227.647568,74.4583784" id="Path"></path>
      <path d="M233.536216,80.7405405 C237.005759,80.7405405 239.818378,77.9279207 239.818378,74.4583784 C239.818378,70.988836 237.005759,68.1762162 233.536216,68.1762162 C230.066674,68.1762162 227.254054,70.988836 227.254054,74.4583784 C227.254054,77.9279207 230.066674,80.7405405 233.536216,80.7405405 Z M233.536216,79.9535135 C230.501337,79.9535135 228.041081,77.4932577 228.041081,74.4583784 C228.041081,71.423499 230.501337,68.9632432 233.536216,68.9632432 C236.571096,68.9632432 239.031351,71.423499 239.031351,74.4583784 C239.031351,77.4932577 236.571096,79.9535135 233.536216,79.9535135 Z" id="Oval"></path>
      <path d="M0.407567568,97.2118919 C0.407567568,100.464103 3.04400537,103.100541 6.29621622,103.100541 C9.54842706,103.100541 12.1848649,100.464103 12.1848649,97.2118919 C12.1848649,93.959681 9.54842706,91.3232432 6.29621622,91.3232432 C3.04400537,91.3232432 0.407567568,93.959681 0.407567568,97.2118919" id="Path"></path>
      <path d="M6.28216216,103.465946 C9.75170452,103.465946 12.5643243,100.653326 12.5643243,97.1837838 C12.5643243,93.7142414 9.75170452,90.9016216 6.28216216,90.9016216 C2.8126198,90.9016216 0,93.7142414 0,97.1837838 C0,100.653326 2.8126198,103.465946 6.28216216,103.465946 Z M6.28216216,102.678919 C3.24728283,102.678919 0.787027027,100.218663 0.787027027,97.1837838 C0.787027027,94.1489045 3.24728283,91.6886486 6.28216216,91.6886486 C9.3170415,91.6886486 11.7772973,94.1489045 11.7772973,97.1837838 C11.7772973,100.218663 9.3170415,102.678919 6.28216216,102.678919 Z" id="Oval"></path>
      <path d="M113.97838,97.210888 C113.972286,99.5996693 115.406563,101.756626 117.611763,102.674999 C119.816962,103.593371 122.358286,103.092084 124.04957,101.405114 C125.740855,99.718144 126.248631,97.1781092 125.335894,94.9705711 C124.423157,92.7630331 122.269871,91.323251 119.881082,91.3232432 C116.626981,91.3232327 113.986681,93.9567974 113.97838,97.210888 Z M114.765404,97.2128958 C114.772599,94.3926838 117.060859,92.1102611 119.88108,92.1102703 C121.951364,92.110277 123.817545,93.3580881 124.608584,95.2712878 C125.399622,97.1844874 124.959549,99.3858509 123.493769,100.847892 C122.027989,102.309933 119.825509,102.744381 117.914336,101.948458 C116.003163,101.152535 114.760123,99.2831728 114.765404,97.2128958 Z" id="Path"></path>
      <path d="M227.647568,97.2118919 C227.647568,100.464103 230.284005,103.100541 233.536216,103.100541 C236.788427,103.100541 239.424865,100.464103 239.424865,97.2118919 C239.424865,93.959681 236.788427,91.3232432 233.536216,91.3232432 C230.287211,91.3309698 227.655294,93.9628869 227.647568,97.2118919" id="Path"></path>
      <path d="M233.536216,103.465946 C237.005759,103.465946 239.818378,100.653326 239.818378,97.1837838 C239.818378,93.7142414 237.005759,90.9016216 233.536216,90.9016216 C230.066674,90.9016216 227.254054,93.7142414 227.254054,97.1837838 C227.254054,100.653326 230.066674,103.465946 233.536216,103.465946 Z M233.536216,102.678919 C230.501337,102.678919 228.041081,100.218663 228.041081,97.1837838 C228.041081,94.1489045 230.501337,91.6886486 233.536216,91.6886486 C236.571096,91.6886486 239.031351,94.1489045 239.031351,97.1837838 C239.031351,100.218663 236.571096,102.678919 233.536216,102.678919 Z" id="Oval"></path>
      <path d="M45.8443256,29.0065637 C45.8382318,31.3953449 47.272509,33.5523016 49.4777085,34.4706742 C51.682908,35.3890468 54.2242315,34.8877602 55.9155162,33.2007899 C57.606801,31.5138196 58.1145772,28.9737848 57.2018403,26.7662468 C56.2891034,24.5587087 54.1358173,23.1189267 51.7470283,23.1189189 C48.4929271,23.1189083 45.8526269,25.752473 45.8443256,29.0065637 Z M46.6313501,29.0085714 C46.6385445,26.1883595 48.9268047,23.9059368 51.7470257,23.9059459 C53.8173095,23.9059527 55.6834908,25.1537638 56.4745295,27.0669634 C57.2655681,28.9801631 56.8254954,31.1815266 55.3597153,32.6435675 C53.8939351,34.1056084 51.6914548,34.5400568 49.7802819,33.7441339 C47.869109,32.9482109 46.6260687,31.0788485 46.6313501,29.0085714 Z" id="Path"></path>
      <path d="M159.471353,29.006565 C159.465263,31.396715 160.901174,33.5546219 163.108224,34.472108 C165.315275,35.389594 167.857781,34.8855416 169.547877,33.1954448 C171.237974,31.5053481 171.742026,28.9628429 170.82454,26.755792 C169.907054,24.5487412 167.749147,23.1128306 165.358997,23.1189202 C162.110775,23.127196 159.479628,25.7583427 159.471353,29.006565 Z M160.258377,29.0085702 C160.265549,26.1934441 162.545877,23.913117 165.361003,23.9059447 C167.432466,23.900667 169.302652,25.1451229 170.097807,27.0579003 C170.892961,28.9706777 170.456116,31.1741822 168.991365,32.6389327 C167.526615,34.1036832 165.32311,34.5405287 163.410333,33.7453741 C161.497555,32.9502195 160.253099,31.0800335 160.258377,29.0085702 Z" id="Path"></path>
      <path d="M45.8443256,74.4573745 C45.8382318,76.8461557 47.272509,79.0031124 49.4777085,79.921485 C51.682908,80.8398576 54.2242315,80.338571 55.9155162,78.6516007 C57.606801,76.9646304 58.1145772,74.4245956 57.2018403,72.2170576 C56.2891034,70.0095196 54.1358173,68.5697375 51.7470283,68.5697297 C48.4929271,68.5697191 45.8526269,71.2032839 45.8443256,74.4573745 Z M46.6313501,74.4593822 C46.6385445,71.6391703 48.9268047,69.3567476 51.7470257,69.3567568 C53.8173095,69.3567635 55.6834908,70.6045746 56.4745295,72.5177742 C57.2655681,74.4309739 56.8254954,76.6323374 55.3597153,78.0943783 C53.8939351,79.5564192 51.6914548,79.9908676 49.7802819,79.1949447 C47.869109,78.3990218 46.6260687,76.5296593 46.6313501,74.4593822 Z" id="Path"></path>
      <path d="M159.471353,74.4573758 C159.465263,76.8475258 160.901174,79.0054327 163.108224,79.9229188 C165.315275,80.8404049 167.857781,80.3363524 169.547877,78.6462557 C171.237974,76.9561589 171.742026,74.4136537 170.82454,72.2066028 C169.907054,69.999552 167.749147,68.5636414 165.358997,68.569731 C162.110775,68.5780068 159.479628,71.2091535 159.471353,74.4573758 Z M160.258377,74.459381 C160.265549,71.644255 162.545877,69.3639278 165.361003,69.3567555 C167.432466,69.3514778 169.302652,70.5959337 170.097807,72.5087111 C170.892961,74.4214885 170.456116,76.624993 168.991365,78.0897435 C167.526615,79.554494 165.32311,79.9913395 163.410333,79.1961849 C161.497555,78.4010303 160.253099,76.5308443 160.258377,74.459381 Z" id="Path"></path>
      <path d="M45.8583784,97.2118919 C45.8583784,100.464103 48.4948162,103.100541 51.747027,103.100541 C54.9992379,103.100541 57.6356757,100.464103 57.6356757,97.2118919 C57.6356757,93.959681 54.9992379,91.3232432 51.747027,91.3232432 C48.4948162,91.3232432 45.8583784,93.959681 45.8583784,97.2118919" id="Path"></path>
      <path d="M51.732973,103.465946 C55.2025153,103.465946 58.0151351,100.653326 58.0151351,97.1837838 C58.0151351,93.7142414 55.2025153,90.9016216 51.732973,90.9016216 C48.2634306,90.9016216 45.4508108,93.7142414 45.4508108,97.1837838 C45.4508108,100.653326 48.2634306,103.465946 51.732973,103.465946 Z M51.732973,102.678919 C48.6980936,102.678919 46.2378378,100.218663 46.2378378,97.1837838 C46.2378378,94.1489045 48.6980936,91.6886486 51.732973,91.6886486 C54.7678523,91.6886486 57.2281081,94.1489045 57.2281081,97.1837838 C57.2281081,100.218663 54.7678523,102.678919 51.732973,102.678919 Z" id="Oval"></path>
      <path d="M159.471353,97.2108893 C159.465263,99.6010393 160.901174,101.758946 163.108224,102.676432 C165.315275,103.593918 167.857781,103.089866 169.547877,101.399769 C171.237974,99.7096724 171.742026,97.1671672 170.82454,94.9601164 C169.907054,92.7530655 167.749147,91.3171549 165.358997,91.3232445 C162.110775,91.3315203 159.479628,93.962667 159.471353,97.2108893 Z M160.258377,97.2128945 C160.265549,94.3977685 162.545877,92.1174413 165.361003,92.110269 C167.432466,92.1049914 169.302652,93.3494472 170.097807,95.2622246 C170.892961,97.175002 170.456116,99.3785065 168.991365,100.843257 C167.526615,102.308008 165.32311,102.744853 163.410333,101.949698 C161.497555,101.154544 160.253099,99.2843578 160.258377,97.2128945 Z" id="Path"></path>
    </g>
  </svg>
)

export default Splash
