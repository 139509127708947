import React from 'react'
import { css } from '@emotion/core'

import ClientItem from './client-item'

const ProjectNavigation = ({ navStore, projectStore, clients }) => (
  <div css={theme => css(`
    padding-top: 5rem;

    @media (min-width: ${theme.breakpoints.md}) {
      padding-top: 0;
    }
  `)}>
    {clients.map(client =>
      <ClientItem
        key={`nav-client-${client.id}`}
        client={client}
        projectStore={projectStore}
        navStore={navStore}
      />
    )}
  </div>
)

export default ProjectNavigation
