import React from 'react'
import { css } from '@emotion/core'

import VideoCount from './video-count'
import H1 from '../shared/typography/Heading1'

class ProjectTitle extends React.Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(e) {
    e.preventDefault()
    e.stopPropagation()
    this.props.navStore.openProjectIndex()
  }

  render() {
    const {
      navStore,
      navOpen,
      title,
      showVideoCount,
      videoIndex,
      project,
      projectStore
    } = this.props
    return (
      <H1>
        <span
          onMouseUp={this.handleClick}
          onTouchEnd={this.handleClick}
          onMouseEnter={() => navStore.showNavHover()}
          onMouseLeave={() => navStore.hideNavHover()}
          css={theme => css(`
            pointer-events: ${navOpen ? 'none' : 'auto'};

            @media (min-width: ${theme.breakpoints.md}) {
              white-space: nowrap;
            }
          `)}
        >
          {title}
        </span>
        {showVideoCount &&
          <VideoCount
            videos={project.videos}
            videoIndex={videoIndex}
            projectStore={projectStore}
            navStore={navStore}
          />
        }
      </H1>
    )
  }
}

export default ProjectTitle
