import React from 'react'
import { css } from '@emotion/core'

import H1 from '../shared/typography/Heading1'

const InfoSection = ({ children, columns = 1, columnsMobile }) => (
  <div css={theme => css(`
    margin-bottom: 2rem;
    column-count: ${columnsMobile || columns};
    column-gap: 2.27rem;

    @media (min-width: ${theme.breakpoints.md}) {
      column-count: ${columns};
    }

    a {
      color: ${theme.colours.white};
      text-decoration: none;
    }

    li {
      list-style-type: none;
    }
  `)}>
    <H1>
      {children}
    </H1>
  </div>
)

export default InfoSection
