import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { observer, inject } from 'mobx-react'
import { CSSTransition } from 'react-transition-group'
import { css } from '@emotion/core'

import Brand from './brand'
import SubNavigation from './sub-navigation'
import NavigationItem from './navigation-item'
import AudioToggle from './audio-toggle'
import ClientTitle from './client-title'
import ProjectTitle from './project-title'

const Navigation = inject('projectStore', 'navStore')(
  observer(
    class Nav extends React.Component {
      constructor(props) {
        super(props)
        this.primaryNavEl = React.createRef()
      }
      componentWillMount() {
        const { navStore, data } = this.props
        navStore.init({
          selectedProjects: data.allContentfulSettings.edges[0].node.selectedProjects,
          showreels: data.allContentfulSettings.edges[0].node.showreels,
          archivedProjects: data.allContentfulSettings.edges[0].node.archivedProjects
        })
      }

      render() {
        const { projectStore, navStore, siteTitle } = this.props
        const {
          open,
          selectedProjects,
          showreels,
          archivedProjects,
          activeSection,
          splashOpen
        } = navStore
        const {
          audible,
          videoIndex,
          videoMode,
          project,
          infoOpen: projectInfoOpen,
          activeTitle,
          loading,
          playBlocked
        } = projectStore

        const showVideoCount = project && project.videos && project.videos.length > 1

        return (
          <CSSTransition
            in={!splashOpen}
            timeout={300}
            classNames="nav"
            unmountOnExit
          >
            <header
              data={{
                audible: audible,
                videoMode: videoMode,
                projectInfoOpen: projectInfoOpen,
                activeSection: activeSection
              }}
              css={theme => css(`
                position: fixed;
                width: 100vw;
                height: 100%;
                top: 0;
                padding: 3em 2.5rem;
                color: ${theme.colours.white};
                user-select: none;
                pointer-events: ${open ? 'auto' : 'none'};
                cursor: none;
                transition: opacity 0.3s;
                z-index: 2;
                overflow: scroll;
                // scroll-behavior: smooth;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                // -ms-overflow-style: none;
                // overflow: -moz-scrollbars-none;
                // touch-action: none;

                // &::-webkit-scrollbar {
                //     display: none;
                // }

                &:after {
                  content: '';
                  display: block;
                }

                &.nav-enter {
                  opacity: 0;
                }
                &.nav-enter-active {
                  opacity: 1;
                  transition-delay: 0.5s;
                }
                &.nav-exit {
                  opacity: 1;
                }
                &.nav-exit-active {
                  opacity: 0;
                }

                @media (min-width: ${theme.breakpoints.md}) {
                  padding: 0 8.6%;
                  top: 0;
                  height: 100%;
                  display: grid;
                  grid-template-columns: repeat(4, 25fr);
                  grid-template-rows: repeat(2, 50fr);
                  grid-auto-rows: 50%;
                  grid-column-gap: 2.27em;
                  grid-template-areas: "brand project project project";
                  align-items: flex-start;
                  opacity: ${videoMode ? 0 : 1};
                  overflow: auto;
                }
              `)}
            >
              <div
                className="nav-closed"
                onMouseEnter={() => navStore.showNavHover()}
                onMouseLeave={() => navStore.hideNavHover()}
                css={theme => css(`
                  grid-area: brand;

                  @media (min-width: ${theme.breakpoints.md}) {
                    grid-row: 2;
                  }
              `)}>
                <Brand
                  siteTitle={siteTitle}
                  store={navStore}
                />

                <div
                  ref={this.primaryNavEl}
                  css={theme => css(`
                    opacity: ${open ? 1 : 0};
                    transition: opacity: 0.2s;

                    @media (max-width: ${theme.breakpoints.md}) {
                      transition: height 0s, opacity 0.2s;
                      transition-delay: ${activeSection || !open ? '0s' : '0.2s, 0s'};
                      height: ${open ? `${this.primaryNavEl.current.scrollHeight}px` : '0'};
                    }

                `)}>
                  <NavigationItem
                    section="Projects"
                    active={navStore.activeSection === 'projects'}
                    handleClick={() => navStore.setSection('projects')}
                  />
                  <NavigationItem
                    section="Showreels"
                    active={navStore.activeSection === 'showreels'}
                    handleClick={() => navStore.setSection('showreels')}
                  />
                  <NavigationItem
                    section="Archive"
                    active={navStore.activeSection === 'archive'}
                    handleClick={() => navStore.setSection('archive')}
                  />
                  <NavigationItem
                    section="Information"
                    active={navStore.activeSection === 'information'}
                    handleClick={() => navStore.setSection('information')}
                  />
                </div>
              </div>

              {activeSection && (
                <SubNavigation
                  navStore={navStore}
                  projectStore={projectStore}
                  selectedProjects={selectedProjects}
                  showreels={showreels}
                  archivedProjects={archivedProjects}
                />
              )}

              {project && (
                <>
                  <div
                    css={theme => css(`
                      opacity: ${
                        open ||
                        audible ||
                        projectInfoOpen ||
                        videoMode ? 0 : 1};
                      transition: opacity 0.2s;

                      @media (max-width: ${theme.breakpoints.md}) {
                      }

                      @media (min-width: ${theme.breakpoints.md}) {
                        position: static;
                        grid-row: 2;
                        display: grid;
                        grid-column: 2 / span 2;
                        grid-template-columns: repeat(2, 50fr);
                        grid-column-gap: 2.27em;
                        opacity: ${
                          open ||
                          audible ||
                          videoMode ? 0 : 1};
                      }
                  `)}>
                    <ClientTitle
                      navStore={navStore}
                      name={project.client.name}
                      navOpen={open}
                    />
                    <div
                      css={theme => css(`
                        @media (max-width: ${theme.breakpoints.md}) {
                          display: none;
                        }
                    `)}>
                      <ProjectTitle
                        navStore={navStore}
                        title={activeTitle}
                        showVideoCount={showVideoCount}
                        videoIndex={videoIndex}
                        project={project}
                        projectStore={projectStore}
                        navOpen={open}
                      />
                    </div>
                  </div>

                  <div
                    css={theme => css(`
                      opacity: ${
                        open ||
                        audible ||
                        projectInfoOpen ||
                        videoMode ? 0 : 1};
                      transition: opacity 0.3s;

                      @media (min-width: ${theme.breakpoints.md}) {
                        display: none;
                      }
                  `)}>
                    <ProjectTitle
                      navStore={navStore}
                      title={activeTitle}
                      showVideoCount={showVideoCount}
                      videoIndex={videoIndex}
                      project={project}
                      projectStore={projectStore}
                      navOpen={open}
                    />
                  </div>

                  <AudioToggle
                    videoMode={videoMode}
                    audible={audible}
                    playBlocked={playBlocked}
                    loading={loading}
                    projectStore={projectStore}
                    navOpen={open}
                  />
                </>
              )}
            </header>
          </CSSTransition>
        )
      }
    }
  )
)

Navigation.wrappedComponent.propTypes = {
  projectStore: PropTypes.object.isRequired,
  navStore: PropTypes.object.isRequired
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulSettings {
          edges {
            node {
              selectedProjects {
                id
                title
                slug
                info {
                  childMarkdownRemark {
                    html
                  }
                }
                credits {
                  childMarkdownRemark {
                    html
                  }
                }
                client {
                  id
                  name
                  slug
                }
                videos {
                  id
                  title
                  vimeoUrl
                  poster {
                    id
                    title
                    fluid(maxHeight: 720, quality: 51) {
                      sizes
                      src
                      srcSet
                    }
                  }
                }
                thumbnail {
                  id
                  video {
                    file {
                      url
                    }
                  }
                  poster {
                    id
                    title
                    fluid(maxWidth: 250) {
                      sizes
                      src
                      srcSet
                    }
                  }
                }
              }
              showreels {
                id
                title
                slug
                info {
                  childMarkdownRemark {
                    html
                  }
                }
                credits {
                  childMarkdownRemark {
                    html
                  }
                }
                client {
                  id
                  name
                  slug
                }
                videos {
                  id
                  title
                  vimeoUrl
                  poster {
                    id
                    title
                    fluid(maxHeight: 720, quality: 51) {
                      sizes
                      src
                      srcSet
                    }
                  }
                }
                thumbnail {
                  id
                  video {
                    file {
                      url
                    }
                  }
                  poster {
                    id
                    title
                    fluid(maxWidth: 250) {
                      sizes
                      src
                      srcSet
                    }
                  }
                }
              }
              archivedProjects {
                id
                title
                slug
                info {
                  childMarkdownRemark {
                    html
                  }
                }
                credits {
                  childMarkdownRemark {
                    html
                  }
                }
                client {
                  id
                  name
                  slug
                }
                videos {
                  id
                  title
                  vimeoUrl
                  poster {
                    id
                    title
                    fluid(maxHeight: 720, quality: 51) {
                      sizes
                      src
                      srcSet
                    }
                  }
                }
                thumbnail {
                  id
                  poster {
                    id
                    title
                    fluid(maxWidth: 250) {
                      sizes
                      src
                      srcSet
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Navigation data={data} {...props} />}
  />
)
