import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO({ description, lang, meta, keywords, title }) {
  const { allContentfulSettings } = useStaticQuery(
    graphql`
      query {
        allContentfulSettings {
          edges {
            node {
              title
              seoDescription
              shareImage {
                fluid(maxWidth: 600, quality: 70) {
                  src
                }
              }
            }
          }
        }
      }
    `
  )
  const settings = allContentfulSettings.edges[0].node
  const metaTitle = title || settings.title
  const metaDescription = description || settings.seoDescription
  const image = settings.shareImage ? settings.shareImage.fluid.src : ''

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={[
        {
          name: 'google-site-verification',
          content: 'WjHY0TpmUzEHwOadhW9aY4-xndKcY6kB_vezrQpCeQ4'
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: 'og:image',
          content: image,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: `@golden_hum`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
              name: `keywords`,
              content: keywords.join(`, `),
            } : []
        )
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
}

export default SEO
