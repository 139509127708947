import React from 'react'
import Player from '@vimeo/player'
import { css } from '@emotion/core'

const MAX_VOLUME = 1;

class ProjectVideo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      width: 0,
      height: 0,
      aspectRatio: 1
    }

    this.player = null
    this.autoplayCheckTimer = false
    this.mounted = true
    this.resizeEventTimer = false
    this.playerEl = React.createRef()
    this.progressbarEl = React.createRef()
    this.handleVideoEnd = this.handleVideoEnd.bind(this)
    this.handleWindowResize = this.handleWindowResize.bind(this)
    this.resizeVideo = this.resizeVideo.bind(this)
    this.parseVimeoUrl = this.parseVimeoUrl.bind(this)
    this.hidePoster = this.hidePoster.bind(this)
    this.autoplayCheck = this.autoplayCheck.bind(this)
    this.playVideo = this.playVideo.bind(this)
    this.fader = null
  }

  componentDidMount() {
    this.loadVideo()
    window.addEventListener('resize', this.handleWindowResize)
  }

  componentWillUnmount() {
    this.mounted = false
    this.player.setVolume(0)
    this.player.off('ended', this.handleVideoEnd)
    this.player.destroy()
    window.removeEventListener('resize', this.handleWindowResize)
    clearTimeout(this.fader)
  }

  componentDidUpdate({ audible: prevAudible, activeVideo: prevActiveVideo }) {
    const { audible, activeVideo } = this.props
    if (audible !== prevAudible) {
      this.handleAudioChange()
    }

    if (activeVideo.id !== prevActiveVideo.id) {
      this.loadVideo()
    }
  }

  parseVimeoUrl() {
    const { vimeoUrl } = this.props.activeVideo
    // See https://gist.github.com/yangshun/9892961s
    vimeoUrl.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/)
    return RegExp.$6
  }

  loadVideo() {
    const { videoCount, audible, activeVideo } = this.props
    const { vimeoUrl } = activeVideo
    clearTimeout(this.fader)

    if (this.player) {
      const vimeoId = this.parseVimeoUrl()
      this.player.unload().then(() => {
        this.player.loadVideo(vimeoId)
      })
    } else {
      this.player = new Player(this.playerEl.current, {
        url: vimeoUrl,
        portrait: false,
        title: false,
        loop: videoCount === 1,
        background: 1
      })

      this.player.on('loaded', () => {
        this.autoplayCheckTimer = setTimeout(this.autoplayCheck, 4000)
      })
    }

    this.player.setVolume(audible ? MAX_VOLUME : 0) // originally 0.2

    Promise
      .all([this.player.getVideoWidth(), this.player.getVideoHeight()])
      .then(dimensions => {
        const aspectRatio = dimensions[0] / dimensions[1]
        this.setState({ aspectRatio }, this.resizeVideo)
      })

    this.player.on('timeupdate', this.hidePoster)
    this.player.on('ended', this.handleVideoEnd)
    this.props.projectStore.showLoading()
  }

  autoplayCheck() {
    const { projectStore } = this.props
    const { playBlocked } = projectStore
    if (this.mounted) {
      this.player.getPaused().then(paused => {
        if (paused) {
          projectStore.videoPlayBlocked()
          this.autoplayCheckTimer = setTimeout(this.autoplayCheck, 1000)
        } else if (playBlocked) {
          projectStore.videoPlayUnblocked()
        }
      })
    }
  }

  hidePoster(e) {
    if (e.percent > 0.01) {
      this.player.off('timeupdate', this.hidePoster)
      this.props.projectStore.hideLoading()
      this.setState({
        loading: false
      })
    }
  }

  resizeVideo() {
    const { aspectRatio } = this.state
    let height = window.innerHeight
    let width = height * aspectRatio

    if (width < window.innerWidth) {
      width = window.innerWidth
      height = width / aspectRatio
    }
    this.setState({ width, height })
  }

  handleVideoEnd() {
    this.props.onVideoEnd()
    this.player.off('ended', this.handleVideoEnd)
  }

  handleAudioChange() {
    const { audible, isTouch } = this.props
    let volume = audible ? 0 : MAX_VOLUME
    clearTimeout(this.fader)

    const fadeAudio = () => {
      const newVolume = audible ? volume + 0.01 : volume - 0.01
      volume = Math.round(newVolume * 100) / 100
      if (this.player) {
        this.player.setVolume(volume)
      }

      if (
        (audible && volume < MAX_VOLUME) ||
        (!audible && volume > 0)
      ) {
        this.fader = setTimeout(fadeAudio, 50)
      }
    }

    if (isTouch && this.player) {
      this.player.getPaused().then(paused => {
        if (paused) {
          this.playVideo()
        }
      })
      this.player.setVolume(audible ? 1 : 0)
    } else if (this.player) {
      fadeAudio()
    }
  }

  playVideo() {
    clearTimeout(this.autoplayCheckTimer)
    this.player.play()
    this.props.projectStore.forcePlay()
  }

  handleWindowResize() {
    clearTimeout(this.resizeEventTimer)
    this.resizeEventTimer = setTimeout(this.resizeVideo, 200)
  }

  render() {
    const { width, height, loading } = this.state
    const { videoMode } = this.props

    return (
      <div
        css={theme => css(`
          position: absolute;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100%;
          overflow: hidden;
          background-color: ${videoMode ? theme.colours.black : 'transparent'};
        `)}
      >
        <div
          ref={this.playerEl}
          css={theme => css(`
            position: relative;
            width: ${videoMode ? 85 : 100}vw;
            height: 100%;
            margin: 0 auto;
            opacity: ${loading ? 0 : 1};
            transform: scale(${videoMode ? 1 : 1.1});
            transition: opacity 0.2s;
            pointer-events: none;

            @media (min-width: ${theme.breakpoints.md}) {
              width: 100%;
            }

            iframe {
              position: absolute;
              width: ${videoMode ? `100%` : `${width}px`};
              height: ${videoMode ? `100%` : `${height}px`};
              top: 50%;
              left: 50%;
              transform: translate3d(-50%, -50%, 0);

              @media (min-width: ${theme.breakpoints.md}) {
                width: ${width}px;
                height: ${height}px;
              }
            }
          `)}
        />
      </div>
    )
  }
}

export default ProjectVideo
