import React from 'react'
import { css } from '@emotion/core'

import InfoGroup from './info-group'
import H2 from '../shared/typography/Heading2'

class ProjectInfo extends React.Component {
  constructor(props) {
    super(props)
    this.handleToggleClick = this.handleToggleClick.bind(this)
    this.handleToggleMouseEnter = this.handleToggleMouseEnter.bind(this)
    this.handleToggleMouseLeave = this.handleToggleMouseLeave.bind(this)
  }

  handleToggleClick(e) {
    e.preventDefault()
    e.stopPropagation()
    const { projectStore } = this.props
    projectStore.toggleInfo()
  }

  handleToggleMouseEnter() {
    const { projectStore: store } = this.props
    store.showInfoButtonHover()
  }

  handleToggleMouseLeave() {
    const { projectStore: store } = this.props
    store.hideInfoButtonHover()
  }

  render() {
    const { infoOpen, infoButtonHover, videoIndex, videoMode } = this.props
    const { client, title, videos, info, credits } = this.props.project
    const showVideoCount = videos.length > 1
    return (
      <div
        className="project-info"
        css={theme => css(`
          width: 100%;
          padding: 6rem 6rem 3rem 2.5rem;
          color: ${theme.colours.white};
          opacity: ${videoMode ? 0 : 1};
          position: relative;
          z-index: 1;

          ul {
            margin: 0;
            list-style-type: none;
          }

          li {
            display: inline-block;
          }

          .info-enter {
            opacity: 0;
          }
          .info-enter-active {
            opacity: 1;
            transition-delay: 0.2s;
          }
          .info-exit {
            opacity: 1;
          }
          .info-exit-active {
            opacity: 0;
          }

          @media (min-width: ${theme.breakpoints.md}) {
            position: absolute;
            top: 2rem;
            padding: 0 8.6%;
            display: grid;
            grid-template-columns: repeat(8, 12.8fr);
            grid-column-gap: 2.27em;
            grid-template-areas:"toggle title info info info info credits credits";
            align-items: flex-start;

            .info-enter-active {
              transition-delay: 0s;
            }
          }
        `)}
      >
        <div
          css={theme => css(`
            grid-area: toggle;
            position: absolute;
            top: 2rem;
            right: 1.5rem;
            user-select: none;
            opacity: ${infoButtonHover ? 0 : 1};
            transition: opacity 0.2s;

            @media (min-width: ${theme.breakpoints.md}) {
              position: static;
            }
          `)}
        >
          <span
            className={'button'}
            onClick={this.handleToggleClick}
            onTouchEnd={this.handleToggleClick}
            onMouseEnter={this.handleToggleMouseEnter}
            onMouseLeave={this.handleToggleMouseLeave}
            css={theme => css(`
              font-size: 2em;
              padding: 1rem;
              display: block;
              line-height: 1.3rem;

              @media (min-width: ${theme.breakpoints.md}) {
                position: static;
                transform: translate3d(-1rem, -1rem, 0);
              }
            `)}
          >
            {infoOpen ? '×' : '+'}
          </span>
        </div>
        <InfoGroup {...this.props}>
          <div css={theme => css(`
            grid-area: title;
            margin-bottom: 1.2rem;
            transition: opacity 0.2s;
          `)}>
            <H2>{client.name}</H2>
            <H2>{title}</H2>
            {showVideoCount &&
              <H2>{videoIndex + 1}/{videos.length}</H2>
            }
          </div>
        </InfoGroup>
        <InfoGroup {...this.props}>
          {info && info.childMarkdownRemark && (
            <div css={theme => css(`
              grid-area: info;
              margin-bottom: 1.2rem;
              transition: opacity 0.2s;
            `)}>
              <H2>
                <div
                  className="markdown"
                  dangerouslySetInnerHTML={{
                    __html: info.childMarkdownRemark.html
                  }}
                />
              </H2>
            </div>
          )}
        </InfoGroup>
        <InfoGroup {...this.props}>
          {credits && credits.childMarkdownRemark && (
            <div css={theme => css(`
              grid-area: credits;
              margin-bottom: 1.2rem;
              transition: opacity 0.2s;
            `)}>
              <H2>
                <div
                  className="markdown"
                  dangerouslySetInnerHTML={{
                    __html: credits.childMarkdownRemark.html
                  }}
                />
              </H2>
            </div>
          )}
        </InfoGroup>
      </div>
    )
  }
}

export default ProjectInfo
